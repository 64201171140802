import React, { useEffect } from 'react';

import { Progress } from 'antd';
import 'antd/dist/antd.css';

import api from '../../../../services/api';

import customers from '../../../../assets/dashboard/customers.png';

import {
  Container,
  CustomersSection,
  Title,
  CustomerInfo,
  PaymentSection,
  CustomersWrapper,
} from './styles';

const Customers = () => {
  useEffect(() => {
    api
      .get(
        'users?aggregation=lifetime-value&startDate=2021-01-10&endDate=2021-01-14',
      )
      .then((response) => {
        console.log(response.data);
      });
  });
  return (
    <Container>
      <CustomersSection>
        <Title>
          <img src={customers} />
          <h2>Clientes</h2>
        </Title>
        <CustomersWrapper>
          <CustomerInfo>
            <span>Valor vitalício por cliente</span>
            <h3>319,61</h3>
          </CustomerInfo>
          <CustomerInfo>
            <span>Vendas por cliente</span>
            <h3>1,04</h3>
          </CustomerInfo>
        </CustomersWrapper>
      </CustomersSection>
      {/* <PaymentSection>
        <h2>Formas de pagamento - Clientes</h2>

        <b>
          Cartão de crédito<span> (72%)</span>
        </b>
        <Progress
          percent={72}
          strokeLinecap="square"
          strokeColor="#83E38F"
          trailColor="#EBEBEB"
          strokeWidth={12}
          showInfo={false}
        />

        <b>
          Boleto Bancário<span> (25%)</span>
        </b>
        <Progress
          percent={25}
          strokeLinecap="square"
          strokeColor="#83E38F"
          trailColor="#EBEBEB"
          strokeWidth={12}
          showInfo={false}
        />
      </PaymentSection> */}
    </Container>
  );
};

export default Customers;
