import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 5px;

  .ant-select-selector {
    font-family: 'Aller-Bold';
    font-size: 18px;
    color: #007E00;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2) !important;
  }

  .ant-select-item *{
    color: #007E00 !important;
  }
`;