import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Menu, Dropdown, Button } from 'antd';
import 'antd/dist/antd.css';

import Header from '../../components/Header';
// import Button from '../../components/Button';
import FormRegistration from './components/FormRegistration';
import Plans from './components/Plans';

import { Container, Content, Row } from './styles';

const Registration = () => {
  const [showFormRegistration, setShowFormRegistration] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/register-serie-class">Séries</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/register-serie-class">Aulas</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/register-podcasts">Podcasts</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/register-meditations">Meditações</Link>
      </Menu.Item>
    </Menu>
  );

  function renderMenu() {
    if (!showFormRegistration) {
      return (
        <>
          <h1>Cadastre Aulas, Séries e Planos</h1>
          <Row>
            {/* <Button onClick={() => setShowFormRegistration(true)}>
              Cadastrar
            </Button> */}

            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              trigger={['click']}
              arrow
            >
              <Button>Cadastrar</Button>
            </Dropdown>

            <menu>
              <ul>
                <li>
                  <Link to="/conteudo/lista-de-series">Séries</Link>
                </li>
                <li>
                  <Link to="/conteudo/aulas">Aulas</Link>
                </li>
                <li>
                  <Link to="/conteudo/lista-de-podcasts">Podcasts</Link>
                </li>
                <li>
                  <Link to="/conteudo/lista-de-meditacoes">Meditações</Link>
                </li>
              </ul>
            </menu>
          </Row>
          <Plans />
        </>
      );
    }
  }

  function renderFormRegistration() {
    if (showFormRegistration) {
      return <FormRegistration />;
    }
  }

  return (
    <Container>
      <Header />
      <Content>
        {renderMenu()}
        {renderFormRegistration()}
      </Content>
    </Container>
  );
};

export default Registration;
