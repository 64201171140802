import React, { useEffect, useState } from 'react';

import { Select, Alert } from 'antd';

import { TimePicker } from 'antd';
import moment from 'moment';

import api from '../../../../../../services/api';

import { TimeDiv, Button, FormWrapper } from './styles';

const ScheduleForm = ({ selectedDate, setSelectedDate }) => {
  const [lessons, setLessons] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [meditations, setMeditations] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState('');
  const [choosenDate, setChoosenDate] = useState('');
  const [time, setTime] = useState('00:00');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchAppointmentsOptions();
    setDateToCurrentDate();
  }, [selectedDate]);

  async function fetchAppointmentsOptions() {
    await api.get(`posts`).then((response) => {
      setLessons(response.data.lessons);
      setPodcasts(response.data.podcasts);
      setMeditations(response.data.meditations);
    });
  }

  function setDateToCurrentDate() {
    if (!selectedDate.isoDate) {
      const today = new Date().toISOString().slice(0, -13);
      setChoosenDate(today);
    } else {
      setChoosenDate(selectedDate.isoDate.slice(0, -13));
    }
  }

  const { Option, OptGroup } = Select;
  const format = 'HH:mm';

  const handleSelectedAppointment = (value) => {
    setSelectedAppointment(value);
  };

  function onChangeTime(time) {
    if (time) {
      console.log(time.format('HH:mm'));
      setTime(time.format('HH:mm'));
    } else {
      setTime('00:00');
    }
  }

  function renderLessonsOptions() {
    return lessons.map((lesson) => {
      return (
        <Option value={lesson.post_id} key={lesson.post_id}>
          {lesson.name}
        </Option>
      );
    });
  }

  function renderPodcastsOptions() {
    return podcasts.map((podcast) => {
      return (
        <Option value={podcast.post_id} key={podcast.post_id}>
          {podcast.name}
        </Option>
      );
    });
  }

  function renderMeditationsOptions() {
    return meditations.map((meditation) => {
      return (
        <Option value={meditation.post_id} key={meditation.post_id}>
          {meditation.name}
        </Option>
      );
    });
  }

  async function handleSchedule() {
    const data = {
      date: choosenDate + time + ':00',
      post_id: selectedAppointment,
    };

    console.log(data);

    try {
      const success = await api
        .post(`appointments/drafts/releases`, data)
        .then((response) => {
          console.log(response.data);
        });
      if (success) {
        // api
        //   .get(
        //     `appointments/official?month=${selectedDate.month}&year=${selectedDate.year}&playlist=false`,
        //   )
        //   .then((response) => {
        //     setFullSchedule(response.data.data);
        //   });
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setError(true);
    }

    console.log(data);
  }

  function renderAlertFeedback() {
    if (error) {
      return (
        <Alert
          type="error"
          message={`Houve um erro: ` + errorMessage}
          banner
          closable
          onClose={() => setError(false)}
        />
      );
    }
  }

  return (
    <FormWrapper>
      <label>
        Insira o título
        {lessons ? (
          <Select
            showSearch
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Pesquisar"
            optionFilterProp="children"
            onChange={handleSelectedAppointment}
            bordered={false}
          >
            <OptGroup label="Aulas">{renderLessonsOptions()}</OptGroup>
            <OptGroup label="Podcasts">{renderPodcastsOptions()}</OptGroup>
            <OptGroup label="Meditações">{renderMeditationsOptions()}</OptGroup>
          </Select>
        ) : (
          <small>Carregando opções...</small>
        )}
      </label>
      <TimeDiv>
        <label>
          Qual o horário:
          <TimePicker
            defaultValue={moment('00:00', format)}
            format={format}
            bordered={false}
            showNow={false}
            placeholder={'Selecione a hora'}
            className={'time-input'}
            onChange={onChangeTime}
          />
        </label>
        <Button
          onClick={() => handleSchedule()}
          disabled={!selectedAppointment}
        >
          Agendar
        </Button>
      </TimeDiv>
      {renderAlertFeedback()}
    </FormWrapper>
  );
};

export default ScheduleForm;
