import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  height: 500px !important;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

  > p {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #165011;
  }

  > span {
    position: absolute;
    right: 40px;
    bottom: 20px;
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  width: 120px;
  height: 4px;
  background-color: #165011;
  position: absolute;
  bottom: 40px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-80%);
`;
