import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Card from './components/Card';

import editIcon from '../../assets/frequentlyAskedQuestions/pencil-icon.png';

import { Container, Row, Content } from './styles';
import api from '../../services/api';

const PodcastPlaylist = () => {
  const location = useLocation();
  const { pathname } = location;
  const params = useParams();

  const [podcasts, setPodcasts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    api
      .get(`podcast-series/${params.id}?id=${params.id}&embed=episodes`)
      .then((response) => {
        setPodcasts(response.data.episodes);
        setSearchResults(response.data.episodes);
        console.log(response.data.episodes);
      });
  }, []);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const id = result.draggableId;
    const newOrder = result.destination.index;

    const items = Array.from(searchResults);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSearchResults(items);

    updateEpisodeOrder(id, newOrder);
  }

  function updateEpisodeOrder(id, newOrder) {
    const order = {
      order: newOrder,
    };
    api.patch(`episodes/${id}/order`, order).then((response) => {
      console.log(response);
    });
  }

  function renderPodcasts() {
    if (podcasts) {
      return searchResults.map((podcast, index) => {
        return (
          <Draggable key={podcast.id} draggableId={podcast.id} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Card podcast={podcast} />
              </div>
            )}
          </Draggable>
        );
      });
    }
  }

  const renderAddPodcast = () => {
    if (pathname.includes('meditacao')) {
      return (
        <Link to={`/add-meditation/${params.id}`}>
          <Button>Adicionar meditação</Button>
        </Link>
      );
    } else {
      return (
        <Link to={`/add-podcast/${params.id}`}>
          <Button>Adicionar podcast</Button>
        </Link>
      );
    }
  };

  useEffect(() => {
    const results = podcasts.filter((podcast) =>
      podcast.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    setSearchResults(results);
  }, [searchTerm]);

  return (
    <Container>
      <Header />
      <Content>
        <h1>Playlist</h1>
        <Row>
          <div>
            <Input
              type="text"
              onChange={(event) => setSearchTerm(event.target.value)}
              value={searchTerm}
            />
            <img src={editIcon} alt="Ícone de editar nome da série" />
          </div>
          {renderAddPodcast()}
        </Row>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="dropable-container">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {renderPodcasts()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Content>
    </Container>
  );
};

export default PodcastPlaylist;
