import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  animation: rendering 0.5s;

  > h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }

  div > button {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    width: 260px;
    height: 60px;
  }

  @media (max-width: 42.5rem) {
    > h1 {
      text-align: center;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  /* ant-component customization*/
  .ant-dropdown-trigger {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    width: 260px;
    height: 60px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #61e171;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 0px;
    outline: 0;
    padding: 0px 10px;
    color: #fff;
  }

  > menu {
    margin-left: 120px;
  }

  > menu > ul {
    display: flex;
  }

  > menu > ul > li {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #165011;
    list-style: none;
    margin: 0px 20px;
    padding: 10px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 4px;
    border-left-width: 0px;
    border-style: solid;
  }

  > menu > ul > li:nth-of-type(1) {
    border-color: #80f08c;
  }

  > menu > ul > li:nth-of-type(2) {
    border-color: #b86ef6;
  }

  > menu > ul > li:nth-of-type(3) {
    border-color: #ff9999;
  }

  @media (max-width: 42.5rem) {
    flex-direction: column-reverse;
    > menu {
      margin-left: 0px;
      margin-bottom: 40px;
    }
  }
`;
