import React, { useState } from 'react';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Switch from './components/Switch';

import { Container, Content, Form, Row, Options } from './styles';

const Notifications = () => {
  return (
    <Container>
      <Header />
      <Content>
        <h1>Cadastro de notificações</h1>
        <Row>
          <Form>
            <label>Título da notificação</label>
            <Input />
            <label>Descrição</label>
            <Input />
            <label>Qual o horário:</label>
            <Input type="time" />
            <Button>Gerar Link</Button>
          </Form>
          <Options>
            <div>
              Notificações somente para aulas agendadas
              <Switch />
            </div>
            <div>
              Notificações somente em dias úteis
              <Switch />
            </div>
            <div>
              Notificações somente em fins de semana
              <Switch />
            </div>
          </Options>
        </Row>
      </Content>
    </Container>
  );
};

export default Notifications;
