import Item from './components/Item';

import { Container, LinkPage } from './styles';

const FilterBox = ({
  showFilter,
  setShowFilter,
  selectedFilters,
  setSelectedFilters,
  filterOptions,
}) => {
  function handleClickItem(filter, id) {
    let selected = { filter: filter, id: id };

    const alreadyAdded = selectedFilters.find((item) => item.id === id);
    if (!alreadyAdded) {
      setSelectedFilters([...selectedFilters, selected]);
    }
  }

  return (
    <Container showFilter={showFilter}>
      {/* <div>
        <LinkPage>
          <img
            src={close}
            alt="Ícone de fechar"
            onClick={() => setShowFilter(false)}
          />
        </LinkPage>
      </div> */}
      {filterOptions.map((filterOption) => (
        <Item
          onClick={() => handleClickItem(filterOption.name, filterOption.id)}
          key={filterOption.id}
        >
          {filterOption.name}
        </Item>
      ))}
    </Container>
  );
};

export default FilterBox;
