import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50vw;

  > div:nth-of-type(2) {
    margin: 0 auto;

    > img {
      display: block;
      margin: 0 auto;
      height: 160px;
    }

    > h1 {
      font-family: 'Bartolomeo-Regular';
      font-size: 2.5rem;
      color: #007e00;
    }
  }
`;

export const TopGradient = styled.div`
  height: 200px;
  background: rgb(161, 108, 205);
  background: linear-gradient(
    180deg,
    rgba(161, 108, 205, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const BottomGradient = styled.div`
  height: 200px;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    0deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;
