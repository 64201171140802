import Header from '../../../../components/Header';
import PodcastSeries from '../PodcastRegistration/components/PodcastSeries';

import { Container, Content } from './styles';

export const EditSeries = () => {
  return (
    <Container>
      <Header />
      <Content>
        <h1>Editar Playlist</h1>
        <PodcastSeries />
      </Content>
    </Container>
  );
};

export default EditSeries;
