import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../../../components/Header';
import Classes from '../FormRegistration/components/Classes';

import { Container, Content } from './styles';

export const AddClass = () => {
  const params = useParams();
  const [seriesId, setSeriesId] = useState();

  useEffect(() => {
    setSeriesId(params.id);
  }, []);
  return (
    <Container>
      <Header />
      <Content>
        <h1>Adicionar Aula</h1>
        <Classes seriesId={seriesId} />
      </Content>
    </Container>
  );
};

export default AddClass;
