import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 70px;
  height: 24px;
  border-radius: 10px;
  background-color: #95faa2;
  margin-left: 4px;
`;

export const Circle = styled.div`
  position: absolute;
  right: 0px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #19b441;
`;
