import styled from 'styled-components';

export const Container = styled.div`
  min-width: 600px;
  width: 100%;
  padding: 20px;
  margin: 20px 0px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

  button {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    width: 200px;
    height: 60px;
    margin-left: 60px;
    animation: rendering 0.5s;
  }

  @media (max-width: 53.75rem) {
    min-width: 100%;

    > div:nth-of-type(1) {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > div {
        > div:nth-of-type(2) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    flex: unset;
    width: 160px !important;
    padding: 0 60px;
  }

  img {
    border-radius: 6px;
  }
`;

export const Informations = styled.div`
  margin-left: 20px;
  flex: 1;

  button {
    flex: unset;
    width: 160px !important;
  }

  @media (max-width: 53.75rem) {
    > div:nth-of-type(3) {
      flex-direction: column;
      align-items: center;

      > button {
        margin-top: 20px;
        margin-left: 0px;
      }
    }
  }
`;

export const Thumbnail = styled.img`
  width: 200px;
  height: 120px;
  cursor: pointer;
`;

export const Divider = styled.div`
  margin: 10px 0px;
`;

export const Name = styled.span`
  font-family: 'Aller-Regular';
  color: #165011;
  font-size: 1.5rem;
  display: block;
  cursor: pointer;
`;

export const EditIcon = styled.img`
  height: 40px;
  margin-left: 30px;
`;

export const Time = styled.span`
  font-family: 'Aller-Regular';
  font-size: 1rem;
  color: #707070;

  > img {
    margin-right: 10px;
  }
`;

export const Description = styled.span`
  font-family: 'Aller-Light';
  font-size: 1.4rem;
  color: #5c5c5c;
  max-width: 880px;
  cursor: pointer;
`;

export const Feedback = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 19px;
  color: #61e171;
  width: 140px;
  animation: rendering 0.5s;
  transition: all 0.5s ease;
  cursor: pointer;
  &:after {
    position: absolute;
    opacity: 0;
    content: 'Ocultar video';
    font-size: 19px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    text-align: center;
    width: 170px;
    height: 40px;
    background: rgba(255, 255, 255, 0.9);
    pointer-events: none;
    transition: all 0.5s ease;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;
