import Header from '../../components/Header';

import RouterComponent from './RouterComponent';

import { Container, Content } from './styles';

const Calendar = () => {
  return (
    <Container>
      <Header />
      <Content>
        <RouterComponent />
      </Content>
    </Container>
  );
};

export default Calendar;
