import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;

  > div:nth-of-type(2) {
    display: none;
  }

  animation: rendering 0.5s;

  @media (max-width: 68.75rem) {
    padding: 20px 0px;
    flex-direction: column;
    justify-content: center;

    > div:nth-of-type(1) {
      display: none;
    }

    > div:nth-of-type(2) {
      display: block;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  text-align: center;
  padding: 20px;

  > div:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 2.5rem;
    color: #b3b1b1;
    margin-bottom: 40px;
  }

  > div:nth-of-type(2) {
    margin-bottom: 20px;
  }

  > div:nth-of-type(3) {
    margin-bottom: 20px;
  }

  @media (max-width: 87.5rem) {
    > div:not(:first-child) {
      height: 70px;
    }

    > button {
      height: 70px;
    }
  }

  @media (max-width: 68.75rem) {
    width: 100vw;
  }

  @media (max-width: 35rem) {
    padding: 20px;

    > div:nth-of-type(2) {
      width: 100%;
      height: 60px;
    }

    > div:nth-of-type(3) {
      width: 100%;
      height: 60px;
    }

    > button {
      width: 100%;
      height: 60px;
    }
  }

  @media (max-width: 22.5rem) {
    > div:nth-of-type(2) {
      height: 50px;
    }

    > div:nth-of-type(3) {
      height: 50px;
    }

    > button {
      height: 50px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: 40px;

  > div:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #b3b1b1;
  }

  > a {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    text-decoration: none;
    color: #007e00;
    margin-left: 10px;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #f00;
`;
