import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../../components/Button';

import editIcon from '../../../../assets/frequentlyAskedQuestions/pencil-icon.png';

import { Container, Row, Title, SeeMoreSeries, Feedback } from './styles';

const Card = ({ serie, showEditButton = true }) => {
  const renderEditButton = () => {
    if (showEditButton) {
      return (
        <Link to={`/edit-serie/${serie.id}`}>
          <img src={editIcon} alt="Ícone de editar" />
        </Link>
      );
    }
  };
  return (
    <Container>
      <Row>
        <Title>{serie.title}</Title>
        {renderEditButton()}
      </Row>
      <Row>
        <SeeMoreSeries>
          <Link to={`/conteudo/serie/${serie.id}`}>Ver aulas</Link>
        </SeeMoreSeries>
        <Link to={`/add-class/${serie.id}`}>
          <Button>Adicionar aula</Button>
        </Link>
      </Row>
    </Container>
  );
};

export default Card;
