import Header from '../../components/Header';

import logo from '../../assets/header/logo.png';

import { Container, Content } from './styles';

const NotFound = () => {
  return (
    <Container>
      <Header />
      <Content>
        <img src={logo} alt="Logo do Yoga CO." />
        <h1>Ops! Página não encontrada</h1>
      </Content>
    </Container>
  );
};

export default NotFound;
