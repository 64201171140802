import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin: 0 15px;
  padding: 0 15px;
  label {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    color: #a2a2a2;
    .ant-select {
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      min-width: 290px;
      max-width: 372px;
      width: auto;
      border: unset;

      .ant-select-selector {
        font-size: 18px;
        margin: 15px 0;
        .ant-select-selection-item {
          color: #a2a2a2 !important;
        }
      }
    }
    .ant-picker.time-input {
      width: 120px;
      height: 50px;
      border-radius: 6px;
      color: #a2a2a2;

      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2) !important;
      font-size: 18px;
      input {
        color: #a2a2a2;
        font-size: 18px;
      }
    }
  }
`;

export const TimeDiv = styled.div`
  margin: 2em 0;

  display: flex;
  align-items: flex-end;
`;

export const Button = styled.button`
  cursor: pointer;
  font-size: 19px;
  background: #61e171;
  width: 145px;
  height: 50px;
  border-radius: 6px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2) !important;
  border: unset;
  color: #fff;
  margin-bottom: 8px;
  &:hover {
    opacity: 0.9;
  }
`;
