import React, { useEffect, useState } from 'react';

import { Modal, Alert } from 'antd';

import api from '../../../../../../services/api';
import { FeedbackWrapper, ScheduleTable } from './styles';

const ScheduleFeedBack = ({
  selectedDate,
  setFullPlaylistSchedule,
  fullPlaylistSchedule,
  PlaylistNameAndData,
  setPlaylistNameAndData,
}) => {
  const [day, setDay] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setDay(selectedDate.day);
    if (selectedDate.year && selectedDate.month) {
      fetchPlaylistForSelectedDate();
    } else {
      fetchPlaylistForToday();
    }
  }, [selectedDate.month, selectedDate.year]);

  useEffect(() => {
    getAppointmentsCollection();
  }, [selectedDate]);

  function getAppointmentsCollection() {
    let date = `${selectedDate.year}-${('0' + (selectedDate.month + 1)).slice(
      -2,
    )}-${('0' + selectedDate.day).slice(-2)}`;

    const appointmentsCollection = fullPlaylistSchedule.find(
      (item) => item.date.slice(0, -14) === date,
    );

    if (appointmentsCollection) {
      setAppointments(appointmentsCollection.appointments);
    }
  }

  function fetchPlaylistForSelectedDate() {
    api
      .get(
        `appointments/official?month=${selectedDate.month}&year=${selectedDate.year}&playlist=true`,
      )
      .then((response) => {
        setFullPlaylistSchedule(response.data.data);
      });
  }

  async function fetchPlaylistForToday() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    await api
      .get(`appointments/official?month=${month}&year=${year}&playlist=true`)
      .then((response) => {
        setFullPlaylistSchedule(response.data.data);
      });
  }

  function renderAppointments() {
    if (selectedDate.day) {
      return appointments.map((appointment) => (
        <tr key={appointment.id} onClick={() => handleDelete(appointment.id)}>
          <td>
            <b>{'\u2022'}</b>
          </td>
          <td>Dia {appointment.date.slice(8, -14)}</td>
          <td>
            <p>
              {appointment.post.lesson?.name || appointment.post.episode?.name}
            </p>
          </td>
          <td>
            <span>{appointment.date.slice(11, -8)}</span>
          </td>
        </tr>
      ));
    }
  }

  function handleDelete(id) {
    setDeleteID(id);
    setConfirmDeleteModal(true);
  }

  async function handleDeleteConfirm() {
    setError(false);
    try {
      console.log(deleteID);
      const update = await api.delete(`appointments/${deleteID}`);
      if (update) {
        setConfirmDeleteModal(false);
        handleLocalSchedule();
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setError(true);
    }
  }

  function renderAlertFeedback() {
    if (error) {
      return (
        <Alert
          type="error"
          message={`Houve um erro: ` + errorMessage}
          banner
          closable
          onClose={() => setError(false)}
        />
      );
    }
  }

  function handleLocalSchedule() {
    const newAppointmentList = appointments.filter(
      (appointment) => appointment.id !== deleteID,
    );
    setAppointments(newAppointmentList);
    const schedule = fullPlaylistSchedule;

    schedule.forEach((item) => {
      const editedAppointment = item.appointments.filter(
        (appointment) => appointment.id !== deleteID,
      );

      item.appointments = editedAppointment;
    });

    const newScheduleList = schedule.filter(
      (item) => item.appointments.length > 0,
    );

    setFullPlaylistSchedule(newScheduleList);
  }

  const handleCancel = () => {
    setConfirmDeleteModal(false);
  };

  return (
    <FeedbackWrapper>
      <h2>Cadastrados</h2>
      <ScheduleTable>
        <tbody>{renderAppointments()}</tbody>
      </ScheduleTable>
      <Modal
        title="Tem certeza?"
        visible={confirmDeleteModal}
        onOk={handleDeleteConfirm}
        onCancel={handleCancel}
        okText={'Excluir agendamento'}
        okButtonProps={{ disabled: false, danger: true }}
        cancelButtonProps={{ disabled: false }}
      >
        <p>Quer mesmo exluir esse evento da agenda?</p>
        {renderAlertFeedback()}
      </Modal>
    </FeedbackWrapper>
  );
};

export default ScheduleFeedBack;
