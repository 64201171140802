import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1366px;
  margin: 0 auto;
`;

export const Content = styled.div`
  animation: rendering 0.5s;
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  font-family: 'Aller-Regular';
  display: flex;
  justify-content: center;
`;
