import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  > h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }
`;
