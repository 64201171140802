import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  align-items: flex-start;

  max-width: 600px;
  justify-content: center;
`;

export const Downloads = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Aller-Regular';
  background: linear-gradient(to bottom, #61e171, #fff 40%);
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin: 0 30px 15px 0;
  min-width: 166px;
  max-width: 300px;
  flex: 1;
  min-height: 154px;
  img {
    width: 40px;
    height: auto;
  }
  div {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  div span {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    font-size: 8px;
    color: #007e00;
  }
  div b {
    font-size: 33px;
    line-height: 0.8;
    text-align: center;
    color: #61e171;
  }

  @media (max-width: 500px) {
    margin: 15px;
  }
`;

export const Installs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Aller-Regular';
  background: linear-gradient(to bottom, #b27fdc, #fff 40%);
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin: 0 30px 15px 0;
  min-width: 166px;
  max-width: 300px;
  flex: 1;
  min-height: 154px;
  img {
    width: 50px;
    height: auto;
  }
  div {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  div span {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    color: #652f92;

    font-size: 8px;
  }
  div b {
    color: #a16ccd;
    font-size: 33px;
    line-height: 0.8;
    text-align: center;
  }

  @media (max-width: 500px) {
    margin: 15px;
  }
`;

export const Uninstalls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Aller-Regular';
  background: linear-gradient(to bottom, #ff9999, #fff 40%);
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin: 0 30px 15px 0;
  min-width: 166px;
  max-width: 300px;
  flex: 1;
  min-height: 154px;
  img {
    width: 50px;
    height: auto;
  }
  div {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  div span {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    font-size: 8px;
    color: #fd6565;
  }
  div b {
    color: #ff9999;
    font-size: 33px;
    line-height: 0.8;
    text-align: center;
  }

  @media (max-width: 500px) {
    margin: 15px;
  }
`;

export const Conversions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: 'Aller-Regular';
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  margin: 15px 30px 15px 0;
  min-width: 198px;
  max-width: 300px;
  flex: 1;
  height: 218px;
  h2 {
    text-transform: uppercase;
    font-size: 13px;
    color: #007e00;
    letter-spacing: 3px;
  }
  img {
    width: 153px;
    height: auto;
  }
  b {
    font-size: 25px;
    font-family: 'Aller-Regular';
    color: #0a8b1a;
  }
  @media (max-width: 500px) {
    margin: 15px;
  }
`;

export const Retentions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Aller-Bold';
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  padding: 15px 0 45px 0px;
  /* margin: 30px 30px 15px 15px; */
  margin: 15px 30px 0px 15px;
  width: 320px;
  min-height: 270px;
  height: 300px;
  text-align: center;
  h2 {
    font-size: 17px;
    color: #165011;
    line-height: 0.8;
  }
  h3 {
    font-family: 'Aller-Regular';
    color: #747474;
    font-size: 14px;
  }
  @media (max-width: 500px) {
    margin: 15px 0;
  }
`;
