import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  text-decoration: none;
  padding: 5px;
  margin: 0px 4px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.focus ? '#06E406' : '#BDBABA')};
  border-radius: 3px;
  font-family: 'Aller-Regular';
  font-size: 1rem;
  color: #165011;
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    border-color: #06e406;
    color: #06e406;
  }
`;
