import React, { useState } from 'react';

import Header from '../../components/Header';
import Button from '../../components/Button';

import SelectMonth from './components/SelectMonth';
import CrashInfo from './components/CrashInfo';
import ClassInfo from './components/ClassInfo';

import { Container, Content, Row, MainButtons, TitleContainer } from './styles';

const Report = () => {
  // const [showCrashReport, setShowCrashReport] = useState(false);
  // function renderSection() {
  //   if (showCrashReport) {
  //     return <CrashInfo />;
  //   } else {
  //     return <ClassInfo />;
  //   }
  // }

  return (
    <Container>
      <Header />
      <Content>
        <Row>
          <TitleContainer>
            <h1>Relatório</h1>
            <SelectMonth />
          </TitleContainer>
          {/* <MainButtons>
            <Button onClick={() => setShowCrashReport(true)}>
              Monitoramento de falhas
            </Button>
            <Button onClick={() => setShowCrashReport(false)}>
              Aulas mais praticadas
            </Button>
          </MainButtons> */}
        </Row>
        {/* {renderSection()} */}
        <ClassInfo />
      </Content>
    </Container>
  );
};

export default Report;
