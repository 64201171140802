import React, { useState } from 'react';

import Combobox from './components/Combobox';
import Classes from './components/Classes';
import Series from './components/Series';
import Header from '../../../../components/Header';

import { Container, Content } from './styles';

const FormRegistration = () => {
  const [combobox, setCombobox] = useState('Adicionar aulas');
  const [seriesList, setSeriesList] = useState([]);
  const [seriesId, setSeriesId] = useState('');

  function renderForm() {
    if (combobox !== 'Adicionar série') {
      return <Classes seriesId={seriesId} />;
    }
    return <Series />;
  }

  return (
    <Container>
      <Header />
      <Content>
        <h1>{combobox}</h1>
        <Combobox
          setCombobox={setCombobox}
          seriesList={seriesList}
          setSeriesList={setSeriesList}
          setSeriesId={setSeriesId}
        />
        {renderForm()}
      </Content>
    </Container>
  );
};

export default FormRegistration;
