import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  width: 480px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 0px 20px;
`;

export const FieldInput = styled.input`
  font-family: 'Aller-Regular';
  font-size: 1.5rem;
  color: #a2a2a2;
  border: 0;
  outline: 0;
  width: 100%;
`;
