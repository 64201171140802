import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import api from '../../../../services/api';

import Button from '../../../../components/Button';

import serieThumbnail from '../../../../assets/editSeries/example-serie-img.png';
import watch from '../../../../assets/editSeries/watch.png';
import editIcon from '../../../../assets/frequentlyAskedQuestions/pencil-icon.png';
import checkedCircle from '../../../../assets/checked-circle.png';

import {
  Container,
  Row,
  Thumbnail,
  Name,
  EditIcon,
  Time,
  Description,
  Informations,
  Divider,
  Feedback,
} from './styles';

const Card = ({ lesson, index }) => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [is_published, setIsPublished] = useState(lesson.is_published);

  function handlePublish(id) {
    setDisabled(true);

    const published = {
      is_published: true,
    };

    api.patch(`lessons/${id}/published`, published).then(
      (response) => {
        console.log(response.data);
        setDisabled(false);
        setIsPublished(true);
      },
      (error) => {
        console.log(error);
        alert(error);
        setDisabled(false);
      },
    );
  }

  function handleUnpublish(id) {
    setDisabled(true);

    const published = {
      is_published: false,
    };

    api.patch(`lessons/${id}/published`, published).then(
      (response) => {
        console.log(response.data);
        setDisabled(false);
        setIsPublished(false);
      },
      (error) => {
        console.log(error);
        alert(error);
        setDisabled(false);
      },
    );
  }

  function renderButton() {
    if (is_published) {
      return (
        <Feedback
          onClick={() => handleUnpublish(lesson.id)}
          disabled={disabled}
        >
          Publicado <img src={checkedCircle} />
        </Feedback>
      );
    }
    return (
      <Button onClick={() => handlePublish(lesson.id)} disabled={disabled}>
        Publicar
      </Button>
    );
  }

  return (
    <Container>
      <Row>
        <Thumbnail src={lesson.thumbnail_url} alt="Thumbnail da aula" />
        <Informations>
          <Row>
            <div>
              <Name>{lesson.name}</Name>
              <Time>
                <img src={watch} alt="Ícone de relógio" />
                {lesson.duration}
              </Time>
            </div>
            <Link to={`/edit-class/${lesson.id}`}>
              <EditIcon src={editIcon} alt="Ícone de editar" />
            </Link>
          </Row>
          <Divider></Divider>
          <Row>
            <Description>{lesson.description}</Description>
            {renderButton()}
          </Row>
        </Informations>
      </Row>
    </Container>
  );
};

export default Card;
