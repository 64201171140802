import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  animation: rendering 0.5s;
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;

  h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }
  @media (max-width: 368px) {
    padding: 20px 5px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
