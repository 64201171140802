import logo from '../../../../assets/login/logo.png';

import { Container } from './styles';

const Logo = () => {
  return (
    <Container>
      <img src={logo} alt="Logo do Yoga Co." />
      <h1>Do meu coração para o seu</h1>
    </Container>
  );
};

export default Logo;
