import styled from 'styled-components';

export const Container = styled.div`
  width: 200px;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  margin: 40px 20px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Aller-Bold';
  color: #fff;
  font-size: 1.4rem;
  width: 100%;
  height: 50%;
  background-color: #61e171;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  > span {
    font-family: 'Bartolomeo-Bold';
    display: block;
  }
`;

export const Description = styled.div`
  padding: 20px;

  > div {
    font-family: 'Aller-Regular';
    color: #9a9a9a;
  }
  > div:nth-of-type(1) {
    font-size: 1.2rem;
  }

  > div:nth-of-type(2) {
    font-size: 1rem;
    margin-top: 10px;

    > span {
      font-family: 'Aller-Bold';
      font-size: 1.2rem;
      color: #707070;
    }
  }

  > img {
    margin: 0px 0px 0px auto;
    display: block;
    margin-top: 10px;
  }
`;
