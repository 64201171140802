import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 20px;
  max-width: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0,0,0, .3);
  text-align: center;

  > div:nth-of-type(1) {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 10;
  }

  > span {
    margin: 10px 0px;
  }

  > span:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: ${(props) => (props.type === 'success' ? '#61E171' : 'red')};
  }

  > span:nth-of-type(2) {
    font-family: 'Aller-Regular';
    font-size: 1.2rem;
    color: ${(props) => props.type === 'success' && '#165011'};
  }
`;
