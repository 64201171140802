import Data from './components/Data';
import Customers from './components/Customers';
import Header from '../../components/Header';
import SelectMonth from './components/SelectMonth';

import { Container, Content, Row } from './styles';

const Dashboard = () => {
  return (
    <Container>
      <Header />
      <Content>
        <Row>
          <SelectMonth />
          <Data />
          <Customers />
        </Row>
      </Content>
    </Container>
  );
};

export default Dashboard;
