import { createGlobalStyle } from 'styled-components';

import AllerBold from '../assets/fonts/AllerBold.ttf';
import AllerLight from '../assets/fonts/AllerLight.ttf';
import AllerRegular from '../assets/fonts/AllerRegular.ttf';
import BartolomeoRegular from '../assets/fonts/BartolomeoRegular.ttf';
import BartolomeoBold from '../assets/fonts/BartolomeoBold.ttf';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline-color: unset !important;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  html {
    font-size: 16px;
  }

  @media (max-width: 1400px) {
    html {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    html {
      font-size: 12px;
    }
  }

  @media (max-width: 360px) {
    html {
      font-size: 10px;
    }
  }


  @font-face {
    font-family: Aller-Bold;
    src: local('Aller-Bold'), url(${AllerBold});
  }

  @font-face {
    font-family: Aller-Light;
    src: local('Aller-Light'), url(${AllerLight});
  }

  @font-face {
    font-family: Aller-Regular;
    src: local('Aller-Regular'), url(${AllerRegular});
  }

  @font-face {
    font-family: Bartolomeo-Regular;
    src: local('Bartolomeo-Regular'), url(${BartolomeoRegular});
  }

  @font-face {
    font-family: Bartolomeo-Bold;
    src: local('Bartolomeo-Bold'), url(${BartolomeoBold});
  }

  @keyframes rendering{
    from{
      opacity:0
      }
    to{
        opacity:1
    }
  }

`;
