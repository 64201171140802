import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import menuIcon from '../../assets/sideBar/menu-icon.png';
import cadastro from '../../assets/sideBar/cadastro-aulas.png';
import registrationSelected from '../../assets/sideBar/registrationSelected.png';
import alunos from '../../assets/sideBar/alunos.png';
import calendario from '../../assets/sideBar/calendario.png';
import presentear from '../../assets/sideBar/presentear.png';
import giftSelected from '../../assets/sideBar/giftSelected.png';
import notificacoes from '../../assets/sideBar/notificacoes.png';
import notificationsSelected from '../../assets/sideBar/notifications.png';
import termsOfUseAndPrivacy from '../../assets/sideBar/terms-of-use-privacy.png';
import termsOfUseAndPrivacySelected from '../../assets/sideBar/terms-of-use-privacy-selected.png';
import comentarios from '../../assets/sideBar/comentarios.png';
import commentsSelected from '../../assets/sideBar/commentsSelected.png';

import { Container, Menu } from './styles';

const SideBar = ({ showSideBar, setShowSideBar }) => {
  const [selected, setSelected] = useState('');

  return (
    <Container>
      <Menu showSideBar={showSideBar}>
        <div>
          <div onClick={() => setShowSideBar(false)}>
            <img src={menuIcon} alt="Ícone do Menu" />
          </div>
          <Link to="/refresher">
            <img
              onClick={() => setSelected('registration')}
              src={
                selected === 'registration' ? registrationSelected : cadastro
              }
              alt="Menu de Cadastro"
            />
          </Link>
          <Link to="home">
            <img src={alunos} alt="Menu de Alunos" />
          </Link>
          <Link to="calendar">
            <img src={calendario} alt="Menu do Calendario" />
          </Link>
          <Link to="presentear">
            <img
              onClick={() => setSelected('gift')}
              src={selected === 'gift' ? giftSelected : presentear}
              alt="Menu de Presentear"
            />
          </Link>
          <Link to="notificacoes">
            <img
              onClick={() => setSelected('notifications')}
              src={
                selected === 'notifications'
                  ? notificationsSelected
                  : notificacoes
              }
              alt="Menu de Notificações"
            />
          </Link>
          <Link to="termos-de-uso">
            <img
              onClick={() => setSelected('termsOfUseAndPrivacy')}
              src={
                selected === 'termsOfUseAndPrivacy'
                  ? termsOfUseAndPrivacySelected
                  : termsOfUseAndPrivacy
              }
              alt="Menu de Termos de Uso e Privacidade"
            />
          </Link>
          <Link to="comentarios">
            <img
              onClick={() => setSelected('comments')}
              src={selected === 'comments' ? commentsSelected : comentarios}
              alt="Menu de Comentários"
            />
          </Link>
        </div>
      </Menu>
    </Container>
  );
};

export default SideBar;
