import styled from 'styled-components';
// import Button from '../../components/Button'

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  animation: rendering 0.5s;

  h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }
  @media (max-width: 368px) {
    padding: 20px 5px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > button:nth-child(2) {
    background: transparent;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 310px;
  max-width: 380px;
  margin-bottom: 15px;
`;

export const MainButtons = styled.div`
  display: flex;
  padding: 10px;
  flex-wrap: wrap;

  > button:nth-child(1) {
    flex: 1;
    font-size: 1rem;
    max-width: 280px;
    margin: 0 10px;
    height: 48px;
  }

  > button:nth-child(2) {
    flex: 1;
    font-size: 1rem;
    max-width: 280px;
    background: #b86ef6;
    margin: 0 10px;
    height: 48px;
  }
  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;
