import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import Header from '../../components/Header';

import Card from './components/Card';

import { Container, Content } from './styles';

const ListSeries = () => {
  const [series, setSeries] = useState([]);
  const [serieAvulsa, setSerieAvulsa] = useState();
  const [serieExclusiva, setSerieExclusiva] = useState();

  const serieAvulsaID = process.env.REACT_APP_SINGLE_LESSONS_CHANNEL;
  const serieExclusivaID = process.env.REACT_APP_SINGLE_LESSONS_EXCLUSIVES;

  useEffect(() => {
    api.get('series').then((response) => {
      setSeries(response.data);
      console.log(response.data);
    });

    api.get(`series/${serieAvulsaID}`).then((response) => {
      setSerieAvulsa(response.data);
    });

    api.get(`series/${serieExclusivaID}`).then((response) => {
      setSerieExclusiva(response.data);
    });
  }, []);

  function renderDetachedSerie() {
    if (serieAvulsa) {
      return (
        <Card serie={serieAvulsa} key={serieAvulsa.id} showEditButton={false} />
      );
    }
  }
  function renderDetachedExclusiveSerie() {
    if (serieExclusiva) {
      return (
        <Card
          serie={serieExclusiva}
          key={serieExclusiva.id}
          showEditButton={false}
        />
      );
    }
  }
  return (
    <Container>
      <Header />
      <Content>
        <h1>Séries</h1>
        <div>
          {renderDetachedSerie()}
          {renderDetachedExclusiveSerie()}

          {series
            .filter(
              (serie) =>
                serie.id !== serieAvulsaID && serie.id !== serieExclusivaID,
            )
            .map((serie) => {
              return <Card serie={serie} key={serie.id} />;
            })}
        </div>
      </Content>
    </Container>
  );
};

export default ListSeries;
