import logo from '../../assets/login/logo.png';

import { Container, TopGradient, BottomGradient } from './styles';

const BackgroundGradientWithLogo = () => {
  return (
    <Container>
      <TopGradient />
      <div>
        <img src={logo} alt="Logo do Yoga Co." />
        <h1>Do meu coração para o seu</h1>
      </div>
      <BottomGradient />
    </Container>
  );
};

export default BackgroundGradientWithLogo;
