import React, { useState } from 'react';

import DateCalendar from './components/DateCalendar';
import ScheduleForm from './components/ScheduleForm';
import ScheduleFeedback from './components/ScheduleFeedback';

import { FirstSection, SecondSection } from './styles';

const MonthScheduleSubPage = ({ setSubPage, subPage }) => {
  const [selectedDate, setSelectedDate] = useState({
    year: '',
    month: '',
    day: '',
    isoDate: '',
  });

  const [fullSchedule, setFullSchedule] = useState([]);

  return (
    <>
      <FirstSection>
        <h1>Calendário dos Alunos</h1>
        <DateCalendar
          setSubPage={setSubPage}
          subPage={subPage}
          setSelectedDate={setSelectedDate}
          fullSchedule={fullSchedule}
        />
      </FirstSection>
      <SecondSection>
        <ScheduleForm
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <ScheduleFeedback
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setFullSchedule={setFullSchedule}
          fullSchedule={fullSchedule}
        />
        {/* Modal */}
      </SecondSection>
    </>
  );
};

export default MonthScheduleSubPage;
