import React, { useState } from 'react';

import Card from './components/Card';
import Form from './components/Form';

import { Container } from './styles';

const Plans = () => {
  const [showFormToAddPlan, setShowFormToAddPlan] = useState(false);

  function renderForm() {
    if (showFormToAddPlan) {
      return <Form />;
    }
  }

  return (
    <Container>
      <Card setShowFormToAddPlan={setShowFormToAddPlan} />
      <Card setShowFormToAddPlan={setShowFormToAddPlan} />
      {renderForm()}
    </Container>
  );
};

export default Plans;
