import React, { useState, useEffect } from 'react';

import { ConfigProvider as CalendarWrapper } from 'antd';
import pt_BR from 'antd/lib/locale/pt_BR';
import { Select } from 'antd';
import { Calendar } from 'antd';
import moment from 'moment';

import { DateWrapper } from './styles';

import api from '../../../../../../services/api';

import { SelectSection, ThemeSection, CalendarSection } from './styles';

const DateCalendar = ({
  setSubPage,
  subPage,
  setSelectedDate,
  fullSchedule,
}) => {
  const [daysWithAppointment, setDaysWithAppointment] = useState([]);

  useEffect(() => {
    const daySchedule = [];
    fullSchedule.forEach((item) => daySchedule.push(item.date));

    setDaysWithAppointment(daySchedule);
    // console.log(daysWithAppointment);
  }, [fullSchedule]);

  moment.updateLocale('pt_BR', {
    weekdaysMin: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
  });

  moment.updateLocale('pt_BR', {
    monthsShort: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
  });

  const { Option } = Select;
  function handleSelectChange(value) {
    setSubPage(value);
  }

  function handleOnDateChange(value) {
    let isoDate = value.toISOString();
    let year = parseInt(value.format('YYYY'));
    let day = parseInt(value.format('DD'));
    let month = parseInt(value.format('MM') - 1);

    setSelectedDate({ month: month, year: year, day: day, isoDate: isoDate });
  }

  function dateCellRender(value) {
    const haveAppointment = daysWithAppointment.find(
      (item) => item.slice(0, -14) === value.toISOString().slice(0, -14),
    );
    if (haveAppointment) {
      return '\u2022';
    }
  }

  return (
    <DateWrapper>
      <SelectSection>
        <Select
          defaultValue={subPage}
          bordered={false}
          style={{ width: 290 }}
          onChange={handleSelectChange}
        >
          <Option value="draft">Calendário - Avulsos</Option>
          <Option value="schedule">Calendário - Agendados</Option>
          <Option value="playlist">Calendário - Playlist do mês</Option>
        </Select>
      </SelectSection>
      {/* <ThemeSection>
        <label>
          Defina o tema do mês
          <input></input>
        </label>
      </ThemeSection> */}
      <CalendarSection>
        <CalendarWrapper locale={pt_BR}>
          <Calendar
            dateCellRender={dateCellRender}
            fullscreen={true}
            onChange={handleOnDateChange}
            defaultValue={moment}
          />
        </CalendarWrapper>
      </CalendarSection>
    </DateWrapper>
  );
};

export default DateCalendar;
