import styled from 'styled-components';

export const Container = styled.div`
  > h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  animation: rendering 0.5s;

  > h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }

  div > button {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    width: 260px;
    height: 60px;
  }

  @media (max-width: 42.5rem) {
    > h1 {
      text-align: center;
    }
  }
`;
