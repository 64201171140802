import React, { useState } from 'react';

import Header from '../../components/Header';
import Button from '../../components/Button';

import editIconGreen from '../../assets/frequentlyAskedQuestions/pencil-icon.png';
import editIconWhite from '../../assets/termsOfUseAndPrivacy/pencil-icon-white.png';

import { Container, Content, TextArea, TextAreaContainer, Row } from './styles';

const TermsOfUseAndPrivacy = () => {
  const [showSaveButton, setShowSaveButton] = useState(false);

  return (
    <Container>
      <Header />
      <Content>
        <h1>Termos de uso e Política de Privacidade</h1>
        <Row showSaveButton={showSaveButton}>
          <TextAreaContainer>
            <TextArea />
            <img
              src={editIconGreen}
              alt="Ícone de editar Termos de Uso e Privacidade"
            />
          </TextAreaContainer>
          <div>
            <Button
              onClick={() => setShowSaveButton(true)}
              iconLeft={editIconWhite}
            >
              Editar Termos
            </Button>
            <Button
              showSaveButton={showSaveButton}
              onClick={() => setShowSaveButton(false)}
            >
              Salvar
            </Button>
          </div>
        </Row>
      </Content>
    </Container>
  );
};

export default TermsOfUseAndPrivacy;
