import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 40px;
  background-color: #f2f4f7;

  > div:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #8430ca;
    margin-left: 74px;
  }

  @media (max-width: 65rem) {
    > div:nth-of-type(1) {
      margin-left: 0px;
      text-align: center;
    }
  }

  @media (max-width: 35rem) {
    padding: 20px;
  }
`;

export const Comment = styled.div`
  display: flex;
  margin-top: 20px;
  width: 40%;

  > img {
    width: 56px;
    height: 56px;
  }

  > div {
    margin-left: 20px;
    > div:nth-of-type(1) {
      font-family: 'Aller-Bold';
      font-size: 1.5rem;
      color: #165011;
    }

    > div:nth-of-type(2) {
      font-family: 'Aller-Light';
      font-size: 1.5rem;
      color: #5c5c5c;
    }
  }

  @media (max-width: 65rem) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const Interact = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  > div {
    font-size: 1.2rem;
  }

  > div:nth-of-type(1) {
    font-family: 'Aller-Light';
    color: #949494;
  }

  > div:nth-of-type(3) {
    font-family: 'Aller-Regular';
    color: #9d5ed1;
  }

  > div:nth-of-type(5) {
    font-family: 'Aller-Regular';
    color: #165011;
  }
`;

export const Divider = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 10px;
  background-color: #165011;
  margin: 0px 20px;
`;

export const ReplyComment = styled.div`
  textarea {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 20px;
    outline: 0;
    border: 0;
    width: 480px;
    height: 180px;

    @media (max-width: 35rem) {
      width: 100%;
    }
  }
  > button {
    margin-left: auto;
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    width: 200px;
    height: 60px;
    margin-top: 40px;
  }

  @media (max-width: 65rem) {
    > button {
      margin: 40px auto 0px auto;
    }
  }

  @media (max-width: 35rem) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 65rem) {
    align-items: center;
    flex-direction: column;
  }
`;
