import React, { useState } from 'react';

import Header from '../../components/Header';
import Button from '../../components/Button';
import Input from '../../components/Input';

import pencilIcon from '../../assets/frequentlyAskedQuestions/pencil-icon.png';

import {
  Container,
  Content,
  Options,
  Row,
  FormCreateQuestion,
  TextArea,
  ListOfQuestions,
  DeleteButton,
  ButtonsWrapper,
  QuestionCard,
  Question,
  Answer,
} from './styles';

const FrequentlyAskedQuestions = () => {
  const [showFormCreateQuestion, setShowFormCreateQuestion] = useState(false);
  const [showListOfQuestions, setShowListOfQuestions] = useState(false);
  const [showFormEditQuestion, setShowFormEditQuestion] = useState(false);

  function renderOptions() {
    if (
      !showFormCreateQuestion &&
      !showListOfQuestions &&
      !showFormEditQuestion
    ) {
      return (
        <Options>
          <h1>Cadastre as perguntas frequentes</h1>
          <Row>
            <Button onClick={() => setShowFormCreateQuestion(true)}>
              Nova pergunta
            </Button>
            <Button onClick={() => setShowListOfQuestions(true)}>
              Minhas perguntas
            </Button>
          </Row>
        </Options>
      );
    }
  }

  function renderFormCreateQuestion() {
    if (showFormCreateQuestion) {
      return (
        <FormCreateQuestion>
          <h1>Cadastre as perguntas frequentes</h1>
          <label>Pergunta:</label>
          <Input />
          <label>Resposta:</label>
          <TextArea />
          <Button onClick={() => setShowFormCreateQuestion(false)}>
            Cadastrar
          </Button>
        </FormCreateQuestion>
      );
    }
  }

  function renderListOfQuestions() {
    if (showListOfQuestions && !showFormEditQuestion) {
      return (
        <ListOfQuestions>
          <h1>Minhas perguntas</h1>
          <QuestionCard>
            <Question>
              {' '}
              Quanto tempo tenho acesso aos Planos?
              <img
                src={pencilIcon}
                alt="Ícone de editar pergunta"
                onClick={() => setShowFormEditQuestion(true)}
              />
            </Question>
            <Answer>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Answer>
          </QuestionCard>
          <QuestionCard>
            <Question>
              {' '}
              Posso cancelar a qualquer momento?
              <img
                src={pencilIcon}
                alt="Ícone de editar pergunta"
                onClick={() => setShowFormEditQuestion(true)}
              />
            </Question>
            <Answer>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Answer>
          </QuestionCard>
          <QuestionCard>
            <Question>
              {' '}
              As aulas são para todos os níveis?
              <img
                src={pencilIcon}
                alt="Ícone de editar pergunta"
                onClick={() => setShowFormEditQuestion(true)}
              />
            </Question>
            <Answer>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Answer>
          </QuestionCard>
          <QuestionCard>
            <Question>
              {' '}
              Posso ter conta com outra pessoa?
              <img
                src={pencilIcon}
                alt="Ícone de editar pergunta"
                onClick={() => setShowFormEditQuestion(true)}
              />
            </Question>
            <Answer>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Answer>
          </QuestionCard>
          <Button onClick={() => setShowListOfQuestions(false)}>Voltar</Button>
        </ListOfQuestions>
      );
    }
  }

  function renderFormEditQuestion() {
    if (showFormEditQuestion) {
      return (
        <FormCreateQuestion>
          <h1>Editar pergunta frequente</h1>
          <label>Pergunta:</label>
          <Input />
          <label>Resposta:</label>
          <TextArea />
          <ButtonsWrapper>
            <div>
              <Button onClick={() => setShowFormEditQuestion(false)}>
                Atualizar
              </Button>
              <DeleteButton>Deletar</DeleteButton>
            </div>
          </ButtonsWrapper>
        </FormCreateQuestion>
      );
    }
  }

  return (
    <Container>
      <Header />
      <Content>
        {renderOptions()}
        {renderFormCreateQuestion()}
        {renderListOfQuestions()}
        {renderFormEditQuestion()}
      </Content>
    </Container>
  );
};

export default FrequentlyAskedQuestions;
