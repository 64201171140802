import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px;
  background-color: #fff;
  width: 300px;
  height: 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

  > select {
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    font-family: 'Aller-Regular';
    font-size: 0.9rem;
    width: 300px;
    color: #007e00;
    padding: 3px 15px;
    cursor: pointer;
  }

  > select optgroup option {
    text-indent: 5px;
  }

  > img {
    height: 8px;
  }

  @media (max-width: 36.25rem) {
    width: 100%;
  }
`;

export const Select = styled.select``;
