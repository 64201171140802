import Header from '../../../../components/Header';
import Classes from '../FormRegistration/components/Classes';

import { Container, Content } from './styles';

export const EditClasses = () => {
  return (
    <Container>
      <Header />
      <Content>
        <h1>Editar Aula</h1>
        <Classes />
      </Content>
    </Container>
  );
};

export default EditClasses;
