import { Container, Circle } from './styles';

const Switch = () => {
  return (
    <Container>
      <Circle />
    </Container>
  );
};

export default Switch;
