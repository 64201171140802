import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api';

import BackgroundGradientWithLogo from '../../components/BackgroundGradientWithLogo';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Logo from './components/Logo';
import LoadModal from '../../components/LoadModal';

import { Container, Form, Tooltip } from './styles';

const Login = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);

  let history = useHistory();

  async function handleSubmit(event) {
    event.preventDefault();

    if (!email || !password) {
      setErrorMessage('Preencha e-mail e senha para continuar!');
    } else {

      setShowLoadModal(true);
      setErrorMessage('');

      const user = {
        email: email,
        password: password,
      };
      try {
        const response = await api.post('sessions', user);

        dispatch({
          type: 'yoga-adm/user/set-token',
          payload: response.data,
        });

        history.push('/conteudo');
      } catch (error) {
        console.error('Ops! ocorreu um erro' + error.response.data.message);
        setErrorMessage(error.response.data.message);
      }

      setShowLoadModal(false);
    }
  }

  return (
    <Container>
      <BackgroundGradientWithLogo />
      <Logo />
      <Form onSubmit={handleSubmit}>
        <div>Faça Login em sua conta</div>
        <Input
          placeholder="Seu email"
          type="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Input
          placeholder="Sua senha"
          type="password"
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Tooltip>{errorMessage}</Tooltip>
        <Button type="submit">Login</Button>
      </Form>
      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default Login;
