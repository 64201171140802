import styled from 'styled-components';

export const DateWrapper = styled.div`
  .ant-select-selector {
    font-size: 18px !important;
    color: #007e00;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2) !important;
    border-radius: 6px !important;
  }

  .ant-select-item * {
    color: #007e00 !important;
  }
`;

export const SelectSection = styled.div`
  padding: 0 15px;
`;

export const ThemeSection = styled.div`
  padding: 0 15px;

  margin: 1em 0;
  label {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    color: #a2a2a2;

    input {
      border: unset;
      width: auto;
      min-width: 290px;
      max-width: 372px;
      height: 60px;
      border-radius: 6px;
      color: #a2a2a2;
      font-size: 18px;
      font-weight: 300;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2) !important;
      padding: 5px;
    }
  }
`;

export const CalendarSection = styled.div`
  margin: 3em 0;

  .ant-picker-calendar {
    padding: 1em;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    @media (max-width: 380px) {
      box-shadow: unset;
    }
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: unset;
    text-align: center;
  }

  .ant-select-selector {
    box-shadow: unset !important;
    border: unset !important;
  }
  .ant-picker-content {
    width: auto;
    min-width: 300px;
    max-width: 588px;
  }

  .ant-picker-calendar-mode-switch {
    display: none;
  }

  .ant-picker-cell {
    transition: color 0.5s;
  }

  /* disable square over selected date */
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today {
    background: unset !important;
  }

  /* disable square on hover */
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: unset !important;
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell:hover
    .ant-picker-calendar-date {
    background: unset;
    color: #a16ccd !important;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top: unset !important;
    background: transparent;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50% 0;
  }

  /* appointment indicator */
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date-content {
    font-size: 25px;
    color: #61e171;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
  }

  .ant-picker-cell-selected {
    background: #a16ccd;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    .ant-picker-calendar-date-value {
      color: #fff !important;
    }
  }
`;
