import styled from 'styled-components';

export const FeedbackWrapper = styled.div`
  margin: 2em 15px;
  padding: 15px;
  h2 {
    font-weight: bold;
    font-size: 20px;
    color: #165011;
  }
  /* @media (min-width: 754px) {
    margin-top: 10em;
  } */
`;

export const ScheduleTable = styled.table`
  margin: 15px 0;
  vertical-align: middle;
  margin-left: 2px;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 300px !important;
  color: #a2a2a2;
  font-size: 11px;
  b {
    font-size: 6px;
    font-size: 25px;
    color: #61e171;
  }
  p {
    font-size: 9px;
    color: #165011;
    margin-bottom: 0;
    max-width: 80px;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #61e171;
    height: 26px;
    width: 44px;
    border-radius: 3px;
    color: #fff;
  }
  td {
    padding: 15px 20px;
  }
  tr {
    transform: scale(1);
    cursor: pointer;
    &:after {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.9);
      content: 'Excluir da agenda';

      color: #f00;
      text-shadow: 0 0px 10px #fff;

      align-items: center;
      justify-content: center;
    }
    &:hover:after {
      display: flex;
      pointer-events: none;
    }
  }

  tr:nth-child(odd) {
    background: #f7f7f7;
    b {
      color: #a16ccd;
    }
    span {
      background: #a16ccd;
    }
  }
`;
