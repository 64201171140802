import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Card from './components/Card';

import editIcon from '../../assets/frequentlyAskedQuestions/pencil-icon.png';

import { Container, Row, Content } from './styles';
import api from '../../services/api';

const Series = () => {
  const params = useParams();

  const [lessons, setLessons] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    api.get(`series/${params.id}?embed=lessons`).then((response) => {
      setLessons(response.data.lessons);
      setSearchResults(response.data.lessons);
    });
  }, []);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const id = result.draggableId;
    const newOrder = result.destination.index;

    const items = Array.from(searchResults);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSearchResults(items);

    updateClassOrder(id, newOrder);
  }

  function updateClassOrder(id, newOrder) {
    const order = {
      order: newOrder,
    };
    api.patch(`lessons/${id}/order`, order).then((response) => {
      console.log(response);
    });
  }

  function renderLessons() {
    if (lessons) {
      return searchResults.map((lesson, index) => {
        return (
          <Draggable key={lesson.id} draggableId={lesson.id} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Card lesson={lesson} />
              </div>
            )}
          </Draggable>
        );
      });
    }
  }

  useEffect(() => {
    const results = lessons.filter((lesson) =>
      lesson.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    setSearchResults(results);
  }, [searchTerm]);

  return (
    <Container>
      <Header />
      <Content>
        <h1>Série</h1>
        <Row>
          <div>
            <Input
              type="text"
              onChange={(event) => setSearchTerm(event.target.value)}
              value={searchTerm}
            />
            <img src={editIcon} alt="Ícone de editar nome da série" />
          </div>
          <Link to={`/add-class/${params.id}`}>
            <Button>Adicionar aula</Button>
          </Link>
        </Row>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="dropable-container">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {renderLessons()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Content>
    </Container>
  );
};

export default Series;
