import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Button from '../../../../components/Button';

import editIcon from '../../../../assets/frequentlyAskedQuestions/pencil-icon.png';

import { Container, Row, Title, SeeMoreSeries, Feedback } from './styles';

const Card = ({ serie, showEditButton = true }) => {
  const location = useLocation();
  const { pathname } = location;

  const renderButtoAdd = () => {
    if (pathname.includes('meditacoes')) {
      return (
        <Link to={`/add-meditation/${serie.id}`}>
          <Button>Adicionar meditação</Button>
        </Link>
      );
    } else {
      return (
        <Link to={`/add-podcast/${serie.id}`}>
          <Button>Adicionar podcast</Button>
        </Link>
      );
    }
  };

  const renderEditButton = () => {
    if (showEditButton) {
      if (pathname.includes('meditacoes')) {
        return (
          <Link to={`/edit-meditation-playlist/${serie.id}`}>
            <img src={editIcon} alt="Ícone de editar" />
          </Link>
        );
      } else {
        return (
          <Link to={`/edit-podcast-playlist/${serie.id}`}>
            <img src={editIcon} alt="Ícone de editar" />
          </Link>
        );
      }
    }
  };

  const renderShowMore = () => {
    if (pathname.includes('meditacoes')) {
      return (
        <Link to={`/conteudo/meditacao-playlist/${serie.id}`}>Ver mais</Link>
      );
    } else {
      return (
        <Link to={`/conteudo/podcast-playlist/${serie.id}`}>Ver mais</Link>
      );
    }
  };
  return (
    <Container>
      <Row>
        <Title>{serie.title}</Title>
        {renderEditButton()}
      </Row>
      <Row>
        <SeeMoreSeries>{renderShowMore()}</SeeMoreSeries>
        {renderButtoAdd()}
      </Row>
    </Container>
  );
};

export default Card;
