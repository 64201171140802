import React, { useEffect, useState } from 'react';

import api from '../../../../../../services/api';

import arrowDown from '../../../../../../assets/registration/arrow-down.png';

import { Container } from './styles';

const Combobox = ({ setCombobox, seriesList, setSeriesList, setSeriesId }) => {
  const serieAvulsaID = process.env.REACT_APP_SINGLE_LESSONS_CHANNEL;
  const serieExclusivaID = process.env.REACT_APP_SINGLE_LESSONS_EXCLUSIVES;

  useEffect(() => {
    api.get('series').then((response) => {
      setSeriesList(response.data);
      console.log(seriesList);
    });
  }, []);

  function renderSeriesOptions() {
    return seriesList
      .filter(
        (serie) => serie.id !== serieAvulsaID && serie.id !== serieExclusivaID,
      )
      .map((serie) => {
        return (
          <option value={serie.id} key={serie.id}>
            &emsp;{serie.title}
          </option>
        );
      });
  }

  function handleSelect(event) {
    if (event === 'Série') {
      setCombobox('Adicionar série');
    } else {
      setCombobox('Adicionar aula');
      setSeriesId(event);
    }
  }

  return (
    <Container>
      <select
        onChange={(event) => handleSelect(event.target.value)}
        required
        defaultValue="default"
      >
        <optgroup label="Séries">{renderSeriesOptions()}</optgroup>

        <optgroup label="Adicionar série">
          <option value="Série">&emsp;Nova série</option>
          <option value="default" disabled hidden>
            Selecione uma série
          </option>
        </optgroup>

        {/* adicionar id da aula exclusiva na versão de produção */}
        <optgroup label="Criar aula avulsa">
          <option value={serieAvulsaID}>&emsp;Aula avulsa do canal</option>
          <option value={serieExclusivaID}>&emsp;Aula avulsa exclusiva</option>
        </optgroup>
      </select>
      <img src={arrowDown} />
    </Container>
  );
};

export default Combobox;
