import styled from 'styled-components';

export const Container = styled.div``;

export const Menu = styled.div`
  z-index: 2;
  position: fixed;
  top: 0px;
  left: ${(props) => (props.showSideBar ? 0 : -160)}px;
  min-height: 100vh;
  width: 160px;
  background-color: #61e171;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: all 0.5s;

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    > div:nth-of-type(1) {
      margin: 20px 0px 20px -40px;
      cursor: pointer;
      > img {
        width: 30px;
      }
    }

    > img {
      width: 33px;
      height: 33px;
      margin-top: 30px;
    }

    > a > img {
      width: 33px;
      height: 33px;
      margin-top: 30px;
    }
  }

  /* Disabled Links */
  a:not(:nth-child(2), :nth-child(4)) {
    opacity: 0.3;
    pointer-events: none;
  }

  @media (max-width: 56.25rem) {
    width: 100px;
  }
`;
