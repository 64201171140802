import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: -2;
  width: 280px;
  height: 550px;
  background-color: #e8e8e8;
  border-radius: 40px;
  padding: 4px;
  margin: 20px auto -300px auto;

  @media (max-width: 72.5rem) {
    display: none;
  }
`;

export const BlankLayer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 40px;
  padding: 10px;
`;

export const GrayLayer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 40px;
  padding: 40px 10px;
  overflow: hidden;

  div + div {
    margin-left: unset;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  img {
    width: 122px;
    height: 60px;
  }
`;

export const Slider = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 430px;
  transform: translateX(-93px);
  margin: 15px 0 30px 0;
  margin-left: 0 !important;

  div {
    display: flex;
    flex-direction: column;

    transform: scale(1.1);
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    border-radius: 13px;
    font-family: 'Aller-Regular';
    width: 140px;
    min-height: 190px;
    img {
      width: 100%;
      border-radius: 15px;
    }
    h4 {
      padding: 4px 10px;
      font-size: 10px;
      color: #165011;
    }
    p {
      padding: 4px 10px;
      font-size: 6px;
      color: #165011;
    }
  }
`;
