import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Combobox from './components/Combobox';
import Podcasts from './components/Podcasts';
import PodcastSeries from './components/PodcastSeries';
import Header from '../../../../components/Header';

import { Container, Content } from './styles';

const FormRegistration = () => {
  const location = useLocation();
  const { pathname } = location;
  const [combobox, setCombobox] = useState('Adicionar playlist');
  const [podcastList, setPodcastList] = useState([]);
  const [podcastId, setPodcastId] = useState('');

  function renderForm() {
    if (combobox !== 'Nova playlist') {
      return <Podcasts podcastId={podcastId} pathname={pathname} />;
    }
    return <PodcastSeries />;
  }

  return (
    <Container>
      <Header />
      <Content>
        <h1>{combobox}</h1>
        <Combobox
          setCombobox={setCombobox}
          podcastList={podcastList}
          setPodcastList={setPodcastList}
          setPodcastId={setPodcastId}
          pathname={pathname}
        />
        {renderForm()}
      </Content>
    </Container>
  );
};

export default FormRegistration;
