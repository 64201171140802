import styled from 'styled-components';

export const Container = styled.div`
  width: 480px;
  height: 80px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  transition: all 0.5s;
  margin-bottom: 40px;

  Button {
    font-size: 18px;
    animation: rendering 0.5s;
  }

  div:nth-child(2) {
    opacity: 0;
    height: 0;
    transition: opacity 0.5s 0.3s;
    * {
      transform: scaleX(0);
    }
  }

  &:hover {
    height: 160px;
  }

  &:hover div:nth-child(2) {
    opacity: 1;
    height: unset;
    * {
      transform: scaleX(1);
    }
  }

  @media (max-width: 36.25rem) {
    width: 100%;
    height: ${(props) => (props.visibleOptions ? 240 : 120)}px;
    button {
      margin-top: 20px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  @media (max-width: 36.25rem) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.span`
  font-family: 'Aller-Light';
  font-size: 1.5rem;
  color: #5c5c5c;
`;

export const SeeMoreSeries = styled.span`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  color: #61e171;
`;

export const Feedback = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 19px;
  color: #61e171;
  width: 140px;
  animation: rendering 0.5s;
  transition: all 0.5s ease;
  cursor: pointer;
  &:after {
    position: absolute;
    opacity: 0;
    content: 'Ocultar série';
    font-size: 19px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    text-align: center;
    width: 170px;
    height: 40px;
    background: rgba(255, 255, 255, 0.9);
    pointer-events: none;
    transition: all 0.5s ease;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;
