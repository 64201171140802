import styled from 'styled-components';

export const Container = styled.div`
  pointer-events: none;
  opacity: 0.3;
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0px 20px 0px;

  button {
    margin-top: 20px;
  }

  @media (max-width: 42.5rem) {
    justify-content: center;
  }
`;
