import React, { useEffect, useState } from 'react';
import api from '../../../../../../services/api';

import arrowDown from '../../../../../../assets/registration/arrow-down.png';

import { Container } from './styles';

const Combobox = ({
  setCombobox,
  podcastList,
  setPodcastList,
  setPodcastId,
  pathname,
}) => {
  const [playlistAvulsaID, setPlaylistAvulsaID] = useState('');
  const [playlistExclusivaID, setPlaylistExclusivaID] = useState('');

  useEffect(() => {
    loadPodcastId();
  }, []);

  const loadPodcastId = () => {
    let type;
    let playlistAvulsaIDLocal;
    if (pathname.includes('meditations')) {
      type = 'meditacao';
      setPlaylistAvulsaID(process.env.REACT_APP_SINGLE_MEDITATIONS);
      setPlaylistExclusivaID(
        process.env.REACT_APP_SINGLE_EXCLUSIVE_MEDITATIONS,
      );
    } else {
      type = 'podcast';
      setPlaylistAvulsaID(process.env.REACT_APP_EXCLUSIVE_PODCAST_EXCLUSIVE);
    }

    api.get(`/podcast-series?type=${type}`).then((response) => {
      setPodcastList(response.data);
      console.log(type);
      console.log(response.data);
    });
  };

  function renderPlaylistOptions() {
    return podcastList
      .filter((serie) => serie.id !== playlistAvulsaID)
      .map((playlist) => {
        return (
          <option value={playlist.id} key={playlist.id}>
            &emsp;{playlist.title}
          </option>
        );
      });
  }

  function renderOptions() {
    if (pathname.includes('meditations')) {
      return (
        <>
          <option value={playlistAvulsaID}>&emsp;Meditação avulsa</option>
          <option value={playlistExclusivaID}>
            &emsp;Meditação avulsa exclusiva
          </option>
        </>
      );
    } else {
      return <option value={playlistAvulsaID}>&emsp;Episódio avulso</option>;
    }
  }

  function handleSelect(event) {
    if (pathname.includes('meditations')) {
      if (event === 'Playlist') {
        setCombobox('Nova playlist');
      } else {
        setCombobox('Adicionar Meditação');
        setPodcastId(event);
      }
    } else {
      if (event === 'Playlist') {
        setCombobox('Nova playlist');
      } else {
        setCombobox('Adicionar podcast');
        setPodcastId(event);
      }
    }
  }

  return (
    <Container>
      <select
        onChange={(event) => handleSelect(event.target.value)}
        required
        defaultValue="default"
      >
        <optgroup label="Playlists">{renderPlaylistOptions()}</optgroup>

        <optgroup label="Nova playlist">
          <option value="Playlist">&emsp;Adicionar Playlist</option>
          <option value="default" disabled hidden>
            Selecione uma playlist
          </option>
        </optgroup>

        {/* adicionar id do episódio exclusivo na versão de produção */}
        <optgroup label="Criar episódio avulso">{renderOptions()}</optgroup>
      </select>
      <img src={arrowDown} />
    </Container>
  );
};

export default Combobox;
