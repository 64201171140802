import React from 'react';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
  const token = useSelector((state) => state.User.token);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (isPrivate === !!token) {
          return <Component />;
        }

        return (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/conteudo',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
