import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 40px;
  padding: 0px 20px;

  > img {
    display: block;
    margin: 0 auto;
    height: 160px;
  }

  > h1 {
    font-family: 'Bartolomeo-Regular';
    font-size: 2.5rem;
    color: #007e00;
    text-align: center;
  }

  @media (max-width: 30rem) {
    margin-bottom: 20px;

    > img {
      height: 120px;
    }
  }

  @media (max-width: 22.5rem) {
    > img {
      height: 80px;
    }
  }
`;
