import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 3em;
  animation: rendering 0.5s;
  textarea {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 20px;
    outline: 0;
    border: 0;
    width: 480px;
    height: 180px;
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 0.3;
  }

  label {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
    display: block;
    margin: 20px 0px;
  }

  > label:nth-of-type(1) {
    margin-top: 60px;
  }

  > div:nth-of-type(1) > div > div {
    width: 400px;
    height: 60px;
  }

  > div:nth-of-type(1) > div:nth-of-type(2) {
    margin-left: 40px;
  }

  @media (max-width: 56.25rem) {
    > div:nth-of-type(1) > div:nth-of-type(2) {
      margin-left: 0px;
    }
  }

  @media (max-width: 36.25rem) {
    textarea {
      width: 100%;
    }

    > div:nth-of-type(1) > div > div {
      width: 100%;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  margin-top: 20px;

  @media (max-width: 72.5rem) {
    flex-direction: column;
  }
`;

export const UploadClass = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > label {
    width: 100%;
    text-align: left;
  }

  > button:nth-of-type(1) {
    border-radius: 40px;
    height: 66px;
    width: 246px;
  }

  > div {
    height: 40px;
    width: 320px;
    border-radius: 40px;
    margin: 20px 0px 40px 0px;
  }

  > button:nth-of-type(2) {
    height: 66px;
    width: 246px;
  }
`;

export const UploadThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;

  > label {
    width: 100%;
    text-align: left;
  }

  > button:nth-of-type(1) {
    border-radius: 40px;
    width: 320px;
    background-color: #c180f7;
  }

  > div {
    height: 40px;
    width: 320px;
    border-radius: 40px;
    margin: 20px 0px 40px 0px;
  }

  > button:nth-of-type(2) {
    width: 320px;
    background-color: #fff;
    border-color: #61e171;
    border-width: 2px;
    border-style: solid;
    color: #61e171;
  }

  input {
    display: none;
  }

  @media (max-width: 56.25rem) {
    margin-left: 0px;
  }

  @media (max-width: 36.25rem) {
    > button:nth-of-type(1) {
      width: 100%;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 40px;

  > button:nth-of-type(1) {
    height: 66px;
    font-size: 26px;
    width: 246px;
    margin-bottom: 20px;
  }

  > button:nth-of-type(2) {
    width: 246px;
    background-color: #fff;
    border-color: #61e171;
    border-width: 2px;
    border-style: solid;
    color: #61e171;
    margin-left: 40px;
  }

  div + div {
    margin-left: 40px;
  }

  @media (max-width: 900px) {
    div + div {
      margin-left: 0;
    }
  }

  @media (max-width: 56.25rem) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > button:nth-of-type(2) {
      margin-left: 0px;
      margin-top: 40px;
    }
  }

  @media (max-width: 36.25rem) {
    width: 100%;

    div {
      width: 100%;
    }
  }

  @media (max-width: 36.25rem) {
    > button:nth-of-type(2) {
      width: 100%;
    }
  }
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-left: 20px;
  }
`;

export const Anchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 220px;
  background-color: #c180f7;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 33px;
  border: 0px;
  outline: 0;
  padding: 0px 10px;
  font-family: 'Aller-Regular';
  font-size: 1.1rem;
  color: #fff;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  &:hover {
    color: #fff;
    background-color: #cb91fb;
  }
`;

export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  width: 100%;
  max-width: 246px;
  height: 66px;
  background-color: #fff;
  border-color: #f00;
  border-width: 2px;
  border-style: solid;
  color: #f00;
  animation: rendering 0.5s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: 0;
  padding: 0px 10px;
  font-family: 'Aller-Bold';
  cursor: pointer;

  @media (min-width: 901px) {
    margin-left: 30px;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #f00;
`;

export const UrlInput = styled.input`
  height: 40px;
  width: 320px;
  border-radius: 40px;
  margin: 20px 0px 40px 0px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  padding: 0px 20px;
  font-family: 'Aller-Regular';
  font-size: 16px;
  color: #a2a2a2;
  border: 0;
  outline: 0;
  width: 100%;
  overflow: visible;
`;
