import React, { useState } from 'react';

import AdmSubPage from '../subpages/AdmSubPage';
import MonthPlaylistSubPage from '../subpages/MonthPlaylistSubPage';
import MonthScheduleSubPage from '../subpages/MonthScheduleSubPage';

const RouterComponent = () => {
  const [subPage, setSubPage] = useState('draft');

  function rendersubPage() {
    switch (subPage) {
      case 'draft':
        return <AdmSubPage setSubPage={setSubPage} subPage={subPage} />;
      case 'playlist':
        return (
          <MonthPlaylistSubPage setSubPage={setSubPage} subPage={subPage} />
        );
      case 'schedule':
        return (
          <MonthScheduleSubPage setSubPage={setSubPage} subPage={subPage} />
        );

      default:
        // return <AdmSubPage setSubPage={setSubPage} subPage={subPage} />;
        return (
          <MonthScheduleSubPage setSubPage={setSubPage} subPage={subPage} />
        );
    }
  }

  return <>{rendersubPage()}</>;
};

export default RouterComponent;
