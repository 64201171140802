import { BrowserRouter, HashRouter, Switch } from 'react-router-dom';
import Route from './Route';

import Login from '../pages/Login';
import FrequentlyAskedQuestions from '../pages/FrequentlyAskedQuestions';
import TermsOfUseAndPrivacy from '../pages/TermsOfUseAndPrivacy';
import Notifications from '../pages/Notifications';
import Comments from '../pages/Comments';
import Gift from '../pages/Gift';
import Registration from '../pages/Registration';
import Serie from '../pages/Serie';
import PodcastPlaylist from '../pages/PodcastPlaylist';
import ListSeries from '../pages/ListSeries';
import ListPodcasts from '../pages/ListPodcasts';
import Class from '../pages/Class';
import Report from '../pages/Report';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';
import Refresher from '../pages/Refresher';

import EditClasses from '../pages/Registration/components/EditClasses';
import EditSeries from '../pages/Registration/components/EditSeries';
import EditPodcastPlaylist from '../pages/Registration/components/EditPodcastPlaylist';
import AddClass from '../pages/Registration/components/AddClass';
import AddPodcast from '../pages/Registration/components/AddPodcast';
import EditPodcasts from '../pages/Registration/components/EditPodcasts';

import FormRegistration from '../pages/Registration/components/FormRegistration';
import PodcastRegistration from '../pages/Registration/components/PodcastRegistration';

import Calendar from '../pages/Calendar';

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
        <Route component={Login} path="/" exact />
        <Route component={Refresher} path="/refresher" isPrivate />
        <Route component={Dashboard} path="/dashboard" isPrivate />
        <Route
          component={FrequentlyAskedQuestions}
          path="/perguntas-frequentes"
          isPrivate
        />
        <Route
          component={TermsOfUseAndPrivacy}
          path="/termos-de-uso"
          isPrivate
        />
        <Route component={Notifications} path="/notificacoes" isPrivate />
        <Route component={Comments} path="/comentarios" isPrivate />
        <Route component={Gift} path="/presentear" isPrivate />
        <Route component={Registration} path="/conteudo" exact isPrivate />
        <Route component={EditClasses} path="/edit-class/:id" exact isPrivate />
        <Route
          component={EditPodcasts}
          path="/edit-podcast/:id"
          exact
          isPrivate
        />
        <Route
          component={EditPodcasts}
          path="/edit-meditation/:id"
          exact
          isPrivate
        />

        <Route
          component={FormRegistration}
          path="/register-serie-class"
          exact
          isPrivate
        />
        <Route
          component={PodcastRegistration}
          path="/register-podcasts"
          exact
          isPrivate
        />
        <Route
          component={PodcastRegistration}
          path="/register-meditations"
          exact
          isPrivate
        />

        <Route component={EditSeries} path="/edit-serie/:id" exact isPrivate />
        <Route
          component={EditPodcastPlaylist}
          path="/edit-podcast-playlist/:id"
          exact
          isPrivate
        />
        <Route
          component={EditPodcastPlaylist}
          path="/edit-meditation-playlist/:id"
          exact
          isPrivate
        />
        <Route component={AddClass} path="/add-class/:id" exact isPrivate />
        <Route component={AddClass} path="/add-class" exact isPrivate />

        <Route component={AddPodcast} path="/add-podcast/:id" exact isPrivate />
        <Route component={AddPodcast} path="/add-podcast" exact isPrivate />

        <Route
          component={AddPodcast}
          path="/add-meditation/:id"
          exact
          isPrivate
        />
        <Route component={AddPodcast} path="/add-meditation" exact isPrivate />

        <Route component={Serie} path="/conteudo/serie/:id" isPrivate />
        <Route
          component={ListSeries}
          path="/conteudo/lista-de-series"
          isPrivate
        />
        <Route
          component={ListPodcasts}
          path="/conteudo/lista-de-podcasts"
          exact
          isPrivate
        />
        <Route
          component={PodcastPlaylist}
          path="/conteudo/podcast-playlist/:id"
          exact
          isPrivate
        />
        <Route
          component={ListPodcasts}
          path="/conteudo/lista-de-meditacoes"
          exact
          isPrivate
        />
        <Route
          component={PodcastPlaylist}
          path="/conteudo/meditacao-playlist/:id"
          exact
          isPrivate
        />

        <Route component={Class} path="/conteudo/aulas" isPrivate />
        <Route component={Report} path="/relatorio" isPrivate />

        <Route component={Calendar} path="/calendar" isPrivate />

        <Route path="*" component={NotFound} isPrivate />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
