import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${(props) => (props.showSideBar ? 1076 : 1336)}px;
  padding: 20px;
  height: 100px;
  background-color: #fff;
  margin: 0 auto;
  transition: all 1s;

  > div:nth-of-type(2) {
    display: flex;
    > img {
      width: 145px;
      height: 35px;
    }
  }
`;

export const Logout = styled.div`
  position: relative;
  > img {
    cursor: pointer;
    transition: all 0.5s;
  }

  &:hover {
    img {
      opacity: 0.3;
    }
    &:after {
      opacity: 1;
    }
  }

  &:after {
    content: 'sair';
    position: absolute;
    z-index: 3;
    font-family: 'Aller-Regular';
    border-radius: 50%;
    color: green;
    font-size: 23px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
    transition: all 0.5s;
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  /* disable header */
  > div {
    opacity: 0.3;
    pointer-events: none;
  }

  > div:nth-of-type(1) > a {
    border-bottom-color: #80f08c;
  }

  > div:nth-of-type(2) > a {
    border-bottom-color: #b86ef6;
  }

  > div:nth-of-type(3) > a {
    border-bottom-color: #80f08c;
  }

  @media (max-width: 65rem) {
    display: none;
  }
`;

export const Item = styled.div`
  cursor: pointer;
  transition: 0.5s all;
  margin-right: 40px;

  ${(props) =>
    props.selected &&
    css`
      padding: 20px;
      background-color: #fff;
      border-style: solid;
      border-radius: 10px;
      border-width: 0px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    `}

  > a {
    color: inherit;
    text-decoration: inherit;
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    padding-bottom: 10px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }
`;

export const MenuIcon = styled.div`
  display: ${(props) => (props.showSideBar ? 'none' : 'block')};
  cursor: pointer;
  > img {
    width: 43px;
    height: 30px;
    cursor: pointer;
  }

  @media (max-width: 78.75rem) {
    display: block;
  }
`;
