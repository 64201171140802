import Header from '../../components/Header';
import Button from '../../components/Button';
import Input from '../../components/Input';

import { Container, Content } from './styles';

const Gift = () => {
  return (
    <Container>
      <Header />
      <Content>
        <h1>Presentear</h1>
        <div>Gerar código para presentear um plano.</div>
        <Button>Gerar Código</Button>
        <Input value="#Yoga.007156" readonly />
      </Content>
    </Container>
  );
};

export default Gift;
