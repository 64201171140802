import React, { useState } from 'react';

import { Container } from './styles';

const Item = ({ children, ...props }) => {
  const [focus, setFocus] = useState(false);

  return <Container {...props}>{children}</Container>;
};

export default Item;
