import styled from 'styled-components';

export const FirstSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 588px;
  min-width: 300px;
  h1 {
    padding: 0 15px;
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }
  @media (max-width: 368px) {
    padding: 20px 5px;
  }
`;

export const SecondSection = styled.div``;
