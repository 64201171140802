import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 42.5rem) {
    width: 100%;
    > button {
      margin: 20px auto;
    }
  }
`;

export const Informations = styled.div`
  width: 600px;
  height: 320px;
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 20px;

  label {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
    display: block;
    margin: 20px 0px;
  }

  span {
    font-family: 'Aller-Bold';
    font-size: 1.5rem;
    color: #165011;
    margin: 20px 0px;
    margin-left: 10px;
  }

  > div:nth-of-type(1) > div > div {
    width: 100%;
    height: 60px;
  }

  > div:nth-of-type(1) > div:nth-of-type(2) {
    margin-left: 40px;
  }

  @media (max-width: 42.5rem) {
    width: 100%;
    height: 420px;

    > div:nth-of-type(1) > div:nth-of-type(2) {
      margin-left: 0px;
    }
  }
`;

export const Row = styled.div`
  display: flex;

  @media (max-width: 42.5rem) {
    flex-direction: column;
  }
`;
