import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 15px;
`;

export const Button = styled.button`
  background: #fff;
  width: 180px;
  padding: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  border: unset;
  border-radius: 3px;
  cursor: pointer;
  font-family: 'Aller-Regular';
  font-weight: 500;
  color: #007e00;
  font-size: 15px;
`;

export const CalendarContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  height: 500px !important;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey,
    0px 0px 500px 500px rgba(255, 255, 255, 0.5);

  > p {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #9ce4a5;
  }

  > span {
    position: absolute;
    right: 40px;
    bottom: 20px;
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #9ce4a5;
    cursor: pointer;
  }

  animation: rendering 0.3s linear;
`;

export const Divider = styled.div`
  width: 120px;
  height: 4px;
  background-color: #9ce4a5;
  position: absolute;
  bottom: 40px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-80%);
`;
