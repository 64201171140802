import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import SideBar from '../SideBar';

import menuMobile from '../../assets/sideBar/menu-mobile.png';
import logo from '../../assets/header/logo.png';
import user from '../../assets/header/user.png';

import { Container, MenuIcon, Options, Item, Logout } from './styles';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showSideBar, setShowSideBar] = useState(false);
  const [option, setOption] = useState('');

  function handleLogout() {
    dispatch({ type: 'yoga-adm/user/logout' });
    history.push('/login');
  }

  return (
    <>
      <SideBar setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
      <Container showSideBar={showSideBar}>
        <MenuIcon
          showSideBar={showSideBar}
          onClick={() => setShowSideBar(true)}
        >
          <img src={menuMobile} alt="Ícone do Menu" />
        </MenuIcon>
        <div>
          <img src={logo} alt="Logo do Yoga CO." />
        </div>

        <Options>
          <Item
            onClick={() => setOption('dashboard')}
            selected={option === 'dashboard' ? true : false}
          >
            <Link to="/dashboard">Dashboard</Link>
          </Item>
          <Item
            onClick={() => setOption('report')}
            selected={option === 'report' ? true : false}
          >
            <Link to="/relatorio">Relatório</Link>
          </Item>
          <Item
            onClick={() => setOption('questions')}
            selected={option === 'questions' ? true : false}
          >
            <Link to="/perguntas-frequentes">Perguntas frequentes</Link>
          </Item>
        </Options>
        <Logout>
          <img
            src={user}
            alt="Foto do usuário"
            onClick={() => handleLogout()}
          />
        </Logout>
      </Container>
    </>
  );
};

export default Header;
