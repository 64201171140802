import exampleThumbnail from '../../../../../../assets/registration/example-thumbnail.png';
import descriptionExample from '../../../../../../assets/registration/description-example.png';

import {
  Container,
  BlankLayer,
  GrayLayer,
  Row,
  Name,
  Description,
  Informations,
  Slider,
} from './styles';

const CellPhoneClassThumbnail = ({
  thumbnail = exampleThumbnail,
  title = '',
  description = '',
  thumbPreview,
}) => {
  return (
    <Container>
      <BlankLayer>
        <GrayLayer>
          <Row>
            <img
              src={descriptionExample}
              alt="Imagem que representa um exemplo de descrição"
            />
          </Row>
          <Slider>
            <img
              src={exampleThumbnail}
              alt="Imagem que representa um exemplo de thumbnail"
            />
            <div>
              <img
                src={thumbPreview || thumbnail}
                alt="Imagem que representa um exemplo de thumbnail"
              />
              <h4>{title || 'Título da Playlist'}</h4>
              <p>{description || 'Descrição da Playlist'}</p>
            </div>
            <img
              src={exampleThumbnail}
              alt="Imagem que representa um exemplo de thumbnail"
            />
          </Slider>
          <Row>
            <img
              src={descriptionExample}
              alt="Imagem que representa um exemplo de descrição"
            />
          </Row>
        </GrayLayer>
      </BlankLayer>
    </Container>
  );
};

export default CellPhoneClassThumbnail;
