import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';

import { Container, Content } from './styles';

const Refresher = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/conteudo');
  });
  return (
    <Container>
      <Header />
    </Container>
  );
};

export default Refresher;
