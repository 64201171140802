import styled from 'styled-components';

export const Container = styled.div`
  width: 280px;
  height: 550px;
  background-color: #e8e8e8;
  border-radius: 40px;
  padding: 4px;
  margin: 20px auto 0px auto;
  /* position: absolute;
  bottom: 0;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%); */

  @media (max-width: 72.5rem) {
    display: none;
  }
`;

export const BlankLayer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 40px;
  padding: 10px;
`;

export const GrayLayer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 40px;
  padding: 40px 10px;
  overflow: hidden;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  > img:nth-of-type(1) {
    width: 122px;
    height: 80px;
    border-radius: 18px;
  }
`;

export const Item = styled.div``;

export const Informations = styled.div`
  width: 100%;
  overflow-wrap: anywhere;
`;

export const Name = styled.div`
  font-family: 'Aller-Regular';
  font-size: 9px;
  width: 100%;
  margin-left: 10px;
  font-weight: 300;
  color: #165011;
`;

export const Description = styled.div`
  font-family: 'Aller-Regular';
  font-size: 0.5rem;
  color: #a2a2a2;
  width: 100%;
  margin-left: 10px;
  margin-top: 10px;
`;
