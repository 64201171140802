import editIcon from '../../../../../../assets/frequentlyAskedQuestions/pencil-icon.png';

import { Container, Title, Description } from './styles';

const Card = ({ setShowFormToAddPlan }) => {
  return (
    <Container>
      <Title>
        PLANO
        <span>ANUAL</span>
      </Title>
      <Description>
        <div>R$ 299,90 / Ano</div>
        <div>
          Ou 12x de <span>R$ 29,90</span>
        </div>
        <img
          onClick={() => setShowFormToAddPlan((prevState) => !prevState)}
          src={editIcon}
          alt="Ícone de editar plano"
        />
      </Description>
    </Container>
  );
};

export default Card;
