import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  animation: rendering 0.5s ease-in-out;
  @media (max-width: 786px) {
    justify-content: center;
  }
`;

export const Series = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;

  > h3 {
    font-family: 'Aller-Bold';
    font-size: clamp(18px, 3vw, 1.2rem);
    color: #165011;
  }
`;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 9px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  min-width: 280px;
  min-height: 410px;
  div:nth-child(2) {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: clamp(14px, 3vw, 0.8rem);
    justify-content: space-around;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    max-height: 40px;
  }
  span img {
    margin-right: 5px;
  }
  div:last-child {
    color: #a16ccd;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  small {
    font-size: clamp(14px, 5vw, 1.3rem) !important;
  }
  h2 {
    color: #a16ccd;
    font-size: clamp(48px, 5vw, 4rem);
    line-height: 0.8;
  }
`;
export const Thumbnail = styled.img`
  width: 280px;
  height: auto;
`;

export const Classes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 15px 15px;
  font-family: 'Aller-Bold';
  > h3 {
    font-size: clamp(18px, 3vw, 1.2rem);
    color: #165011;
  }
  div {
    min-width: 300px;
    max-width: 450px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  div:nth-child(even) {
    background: #f2f4f7 !important;
  }
  div > span {
    border-radius: 50%;
    background: #b86ef6;
    padding: 7px;
    height: 15px;
    width: 15px;
  }

  em {
    font-weight: 500;
    font-style: normal;
    color: #165011;
    padding: 15px;
    min-width: 110px;
    max-width: 250px;
  }

  b {
    background: #b86ef6;
    color: #fff;
    padding: 15px;
    border-radius: 3px;
  }

  b span {
    padding-right: 5px;
  }
  @media (max-width: 679px) {
    div {
      flex-direction: column;
    }
    div > span {
      display: none;
    }
  }
`;
