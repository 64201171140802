import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 3em;
  animation: rendering 0.5s;

  form textarea {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 20px;
    outline: 0;
    border: 0;
    width: 480px;
    height: 180px;
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 0.3;
  }

  form label {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
    display: block;
    margin: 20px 0px;
  }

  > form label:nth-of-type(1) {
    margin-top: 60px;
  }

  @media (max-width: 36.25rem) {
    form textarea {
      width: 100%;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  margin-top: 20px;

  @media (max-width: 72.5rem) {
    flex-direction: column;
  }
`;

export const UploadClass = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > label {
    width: 100%;
    text-align: left;
  }

  > button:nth-of-type(1) {
    border-radius: 6px;
    width: 320px;
    height: 66px;
    font-size: 26px;
  }

  > div {
    height: 40px;
    width: 320px;
    border-radius: 40px;
    margin: 20px 0px 40px 0px;
  }

  > button:nth-of-type(2) {
    width: 320px;
  }

  span {
    height: 60px;
  }

  Input {
    font-size: 16px;
  }
`;

export const UploadThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 40px;

  > label {
    width: 100%;
    text-align: left;
  }

  > div:nth-of-type(1) {
    /* border-radius: 40px;
    width: 320px;
    background-color: #c180f7; */
    margin-bottom: 40px;
    cursor: pointer;
  }

  /* > div {
    height: 40px;
    width: 320px;
    border-radius: 40px;
    margin: 20px 0px 40px 0px;
  } */

  /* > div:nth-of-type(2) {
    width: 320px;
    background-color: #fff;
    border-color: #61e171;
    border-width: 2px;
    border-style: solid;
    color: #61e171;
    pointer-events: none;
    border-radius: 6px;
    height: 66px;
  } */

  span {
    opacity: 0;
    height: 62px;
  }

  input {
    opacity: 0;
    pointer-events: none;
  }

  @media (max-width: 1159px) {
    margin-left: 0px;
  }
`;

export const CustomAnchor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 220px;
  background-color: #c180f7;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 33px;
  border: 0px;
  outline: 0;
  padding: 0px 10px;
  font-family: 'Aller-Regular';
  font-size: 1.1rem;
  color: #fff;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  &:hover {
    color: #fff;
    background-color: #cb91fb;
  }
`;

export const Row = styled.div`
  display: flex;

  > div:nth-of-type(2) > img {
    margin: 0 auto;
    width: 280px;
    display: block;
    margin-top: 40px;
  }

  section {
    margin-left: 30px;
    position: relative;
  }

  @media (max-width: 72.5rem) {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media (max-width: 36.25rem) {
    > div:nth-of-type(1) {
      width: 100%;
      > div:nth-of-type(1) {
        width: 100%;
      }
    }
  }

  @media (max-width: 1165px) {
    section {
      margin-left: 0;
    }
  }
`;

export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  width: 100%;
  max-width: 246px;
  height: 66px;
  background-color: #fff;
  border-color: #f00;
  border-width: 2px;
  border-style: solid;
  color: #f00;
  animation: rendering 0.5s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: 0;
  padding: 0px 10px;
  font-family: 'Aller-Bold';
  cursor: pointer;

  @media (min-width: 901px) {
    margin-left: 30px;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #f00;
  animation: entering 0.5s;
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;

  span {
    font-family: 'Aller-Regular';
    color: #a2a2a2;
    margin-left: 20px;
    width: 260px;
    font-size: 1.4rem;
    margin-top: 30px;
  }
`;
