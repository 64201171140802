import { Select } from 'antd';
import 'antd/dist/antd.css';

import { Container } from './styles';

const SelectMonth = () => {
  const { Option } = Select;

  return (
    <Container>
      {/* <Select defaultValue="Janeiro" 
      style={{ width: 180 }}
      bordered={false}
      >
        <Option value="Janeiro">Janeiro</Option>
        <Option value="Fevereiro">Fevereiro</Option>
        <Option value="Março">Março</Option>
        <Option value="Abril">Abril</Option>
        <Option value="Maio">Maio</Option>
        <Option value="Junho">Junho</Option>
        <Option value="Julho">Julho</Option>
        <Option value="Agosto">Agosto</Option>
        <Option value="Setembro">Setembro</Option>
        <Option value="Outubro">Outubro</Option>
        <Option value="Novembro">Novembro</Option>
        <Option value="Dezembro">Dezembro</Option>
      </Select> */}
    </Container>
  );
};

export default SelectMonth;
