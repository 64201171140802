import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import api from '../../../../../../services/api';

import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import Calendar from '../../../../../../components/Calendar';
import Modal from '../../../../../../components/Modal';
import CellPhoneClassThumbnail from '../CellPhoneClassThumbnail';
import LoadModal from '../../../../../../components/LoadModal';
import Checkbox from '../../../../../../components/Checkbox';

import thumbnailIcon from '../../../../../../assets/registration/thumbnail.png';

import {
  Container,
  UploadThumbnail,
  Row,
  Anchor,
  DeleteButton,
  Tooltip,
  ContainerCheckbox,
} from './styles';

const Series = () => {
  const history = useHistory();
  const params = useParams();

  const [editingMode, setEditingMode] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [theme, setTheme] = useState('');
  const [thumbnail, setThumbnail] = useState();
  const [thumbPreview, setThumbPreview] = useState();
  const [newThumbnailAdded, setNewThumbnailAdded] = useState(false);

  const [submitAttempt, setSubmitAttempt] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteButtonText, setDeleteButtonText] = useState('Tem certeza?');

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [serieExclusive, setSerieExclusive] = useState(false);
  const [seriePartOfChannel, setSeriePartOfChannel] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  let uploadSerieButton = useRef();

  useEffect(() => {
    if (params.id) {
      api.get(`series/${params.id}`).then(
        (response) => {
          fillData(response.data);
          setEditingMode(true);
        },
        (error) => {
          console.log(error.data);
        },
      );
    }
  }, []);

  function fillData(response) {
    console.log(response);
    setTitle(response.title);
    setDescription(response.description);
    if (response.theme !== null) {
      setTheme(response.theme);
    }
    setThumbPreview(response.thumbnail_url);
    setSerieExclusive(response.is_exclusive);
    setSeriePartOfChannel(response.is_from_the_channel);
  }
  // const [value, onChange] = useState(new Date());
  // const [calendarIsVisible, setCalendarIsVisible] = useState(false);

  // function renderCalendar() {
  //   if (calendarIsVisible) {
  //     return <Calendar setVisible={setCalendarIsVisible} />;
  //   }
  // }

  function renderModal() {
    if (modalIsVisible) {
      return (
        <Modal
          title="Sucesso!"
          description="Sua requisição foi processada com sucesso!"
          type="success"
          setVisible={setModalIsVisible}
        />
      );
    }
  }

  function handleUploadThumbnailClick() {
    uploadSerieButton.click();
  }

  function handleSelectImage(event) {
    if (!event.target.files) {
      return;
    }
    setNewThumbnailAdded(true);

    const selectedImage = Array.from(event.target.files);
    setThumbnail(selectedImage);

    const selectedImagePreview = selectedImage.map((image) => {
      return URL.createObjectURL(image);
    });

    setThumbPreview(selectedImagePreview);
  }

  async function handleSubmitChanges() {
    setSubmitAttempt(true);

    setShowLoadModal(true);

    let data;
    if (theme.trim() !== '') {
      data = {
        title: title,
        description: description,
        theme: theme,
        is_exclusive: serieExclusive,
        is_from_the_channel: seriePartOfChannel,
      };
    } else {
      data = {
        title: title,
        description: description,
        is_exclusive: serieExclusive,
        is_from_the_channel: seriePartOfChannel,
      };
    }

    if (newThumbnailAdded) {
      uploadNewThumbNail();
    } else {
      submitChanges(data);
    }

    async function uploadNewThumbNail() {
      const dataImage = new FormData();

      thumbnail.forEach((image) => {
        dataImage.append('thumbnail', image);
      });

      try {
        const addThumb = await api.patch(
          `series/${params.id}/thumbnail`,
          dataImage,
        );

        if (addThumb) {
          submitChanges(data);
        }
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setShowLoadModal(false);
      }
    }

    async function submitChanges(data) {
      try {
        const response = await api.put(`series/${params.id}`, data);

        if (response) {
          console.log(response.data | response.data.message);
          setModalIsVisible(true);

          setTimeout(() => {
            history.push('/conteudo/lista-de-series');
          }, 5000);
        }
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }

      setShowLoadModal(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (editingMode) {
      return handleSubmitChanges();
    }

    setSubmitAttempt(true);

    const data = new FormData();
    data.append('title', title);
    data.append('description', description);
    if (theme.trim() != '') {
      data.append('theme', theme);
    }
    data.append('is_exclusive', serieExclusive);
    data.append('is_from_the_channel', seriePartOfChannel);

    thumbnail.forEach((image) => {
      data.append('thumbnail', image);
    });

    setShowLoadModal(true);
    setErrorMessage('');

    try {
      const response = await api.post('series', data);

      if (response) {
        setModalIsVisible(true);

        setTimeout(() => {
          history.push('/conteudo/lista-de-series');
        }, 5000);
      }
    } catch (error) {
      console.log(error.response.data.message);
      setErrorMessage(error.response.data.message);
    }

    setShowLoadModal(false);
  }

  function handleDelete() {
    setSubmitAttempt(true);
    setShowLoadModal(true);
    api.delete(`series/${params.id}`).then(
      (response) => {
        if (response) {
          console.log(response.data | response.data.message);
          setShowLoadModal(false);
          setModalIsVisible(true);

          setTimeout(() => {
            history.push('/conteudo/lista-de-series');
          }, 5000);
        }
      },
      (error) => {
        setShowLoadModal(false);
        setErrorMessage(error.response.data.message);
        setSubmitAttempt(false);
      },
    );
  }

  function renderDeleteButton() {
    if (editingMode) {
      if (confirmDelete) {
        return (
          <DeleteButton onClick={() => handleDelete()} disabled={submitAttempt}>
            {deleteButtonText}
          </DeleteButton>
        );
      }
      return (
        <DeleteButton onClick={() => setConfirmDelete(true)}>
          Deletar série
        </DeleteButton>
      );
    }
  }

  function renderSubmitButton() {
    if (editingMode) {
      return (
        <Button type="submit" disabled={submitAttempt}>
          Salvar
        </Button>
      );
    }
    return (
      <Button type="submit" disabled={submitAttempt}>
        Adicionar
      </Button>
    );
  }

  function rendererrorMessage() {
    if (errorMessage !== '') {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Row>
          <div>
            <label>Insira o nome</label>
            <Input
              onInput={(event) => setTitle(event.target.value)}
              required={!editingMode}
              value={title}
            />
          </div>
          <div>
            <label>Insira tema</label>
            <Input
              onInput={(event) => setTheme(event.target.value)}
              value={theme}
            />
          </div>
        </Row>
        <Row>
          <div>
            <label>Insira uma descrição</label>
            <textarea
              onInput={(event) => setDescription(event.target.value)}
              value={description}
              required={!editingMode}
            />
          </div>
          <UploadThumbnail>
            <label>Adicionar Thumbnail</label>
            <Anchor onClick={() => handleUploadThumbnailClick()}>
              <img src={thumbnailIcon} />
              Anexar Arquivo
            </Anchor>
            <input
              ref={(input) => (uploadSerieButton = input)}
              onChange={handleSelectImage}
              type="file"
              required={!editingMode}
            />
          </UploadThumbnail>
          <CellPhoneClassThumbnail
            title={title}
            description={description}
            thumbPreview={thumbPreview}
          />
        </Row>
        <ContainerCheckbox>
          <Checkbox checked={serieExclusive} setChecked={setSerieExclusive} />
          <label>Essa série é exclusiva do Yoga Co.?</label>
        </ContainerCheckbox>
        <ContainerCheckbox>
          <Checkbox
            checked={seriePartOfChannel}
            setChecked={setSeriePartOfChannel}
          />
          <label>Essa série faz parte do canal da Pri?</label>
        </ContainerCheckbox>
        <Row>
          {renderSubmitButton()}
          {renderDeleteButton()}
          {/* <Button onClick={() => setCalendarIsVisible(true)}>Agendar aula</Button> */}
        </Row>
        {/* {renderCalendar()} */}
        {renderModal()}
        {rendererrorMessage()}
      </form>
      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default Series;
