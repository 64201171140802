import { ResponsiveBar } from '@nivo/bar';

import download from '../../../../assets/dashboard/download.png';
import install from '../../../../assets/dashboard/app_install.png';
import uninstall from '../../../../assets/dashboard/app_uninstall.png';
import graphic from '../../../../assets/dashboard/graphic_curves_up.png';

import {
  Container,
  Downloads,
  Installs,
  Uninstalls,
  Conversions,
  Retentions,
} from './styles';

export const Data = () => {
  const data = [
    {
      Month: 'Jan',
      Retenção: 80,
      RetençãoColor: 'hsl(250, 70%, 50%)',
      Churn: 20,
      ChurnColor: 'hsl(273,54.1%,71%)',
    },
    {
      Month: 'Fev',
      Retenção: 40,
      RetençãoColor: 'hsl(74, 70%, 50%)',
      Churn: 14,
      ChurnColor: 'hsl(273,54.1%,71%)',
    },
    {
      Month: 'Mar',
      Retenção: 50,
      RetençãoColor: 'hsl(305, 70%, 50%)',
      Churn: 30,
      ChurnColor: 'hsl(273,54.1%,71%)',
    },
  ];

  const colors = ['#B98DDD', '#ECE0F8'];
  return (
    <Container>
      {/* <Downloads>
        <img src={download} />
        <div>
          <span>Downloads</span>
          <b>1000</b>
        </div>
      </Downloads>
      <Installs>
        <img src={install} />
        <div>
          <span>Instalações</span>
          <b>10000</b>
        </div>
      </Installs>
      <Uninstalls>
        <img src={uninstall} />
        <div>
          <span>Desinstalações</span>
          <b>50</b>
        </div>
      </Uninstalls>
      <Conversions>
        <h2>Conversões</h2>
        <img src={graphic} />
        <b>$265.000</b>
      </Conversions> */}
      <Retentions>
        <div>
          <h2>Retenção de usuários em três meses</h2>
          <h3>Média geral (em porcentagem)</h3>
        </div>
        <ResponsiveBar
          data={data}
          keys={['Retenção', 'Churn']}
          indexBy="Month"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.15}
          maxValue={100}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={colors}
          borderColor={{ from: 'color', modifiers: [['darker', '0.5']] }}
          axisTop={null}
          axisRight={null}
          enableGridY={false}
          labelTextColor="#747474"
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
        />
      </Retentions>
    </Container>
  );
};

export default Data;
