import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
  max-width: 375px;
  padding: 1em 5px;
  margin: 15px 0 0 0;
`;

export const CustomersWrapper = styled.div`
  margin: 0;
`;

export const CustomersSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px;
  justify-content: center;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  img {
    width: 90px;
    height: auto;
  }
  h2 {
    font-family: 'Aller-Regular';
    font-size: 18px;
    color: #0a8b1a;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
`;

export const CustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Aller-Regular';
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
  margin: 15px;
  padding: 10px;
  min-width: 185px;
  height: 65px;
  text-align: center;
  span {
    font-size: 8px;
    color: #007e00;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  h3 {
    font-size: 25px;
    color: #61e171;
  }
`;

export const PaymentSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: #165011;
  padding: 20px 15px;
  h2 {
    font-family: 'Aller-Bold';
    color: #165011;
  }

  b {
    font-family: 'Aller-Regular';
  }
  b span {
    color: #aeb0ae;
  }

  .ant-progress-inner {
    border-radius: 0 !important;
  }
`;
