import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  animation: rendering 0.5s;
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  height: 88vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin: 40px;
    text-align: center;
  }
  @media (max-width: 368px) {
    padding: 20px 5px;
  }
`;
