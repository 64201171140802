import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  min-width: 220px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  min-height: 620px;
  @media (max-width: 973px) {
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 220px;
    min-width: 425px;
    margin-bottom: 20px;
  }
  @media (max-width: 568px) {
    min-width: 300px;
  }

  .ant-progress {
    margin: 10px;
  }
  .ant-progress-text {
    font-family: 'Aller-Bold';
    font-size: 1.9rem;
  }

  .ant-progress:nth-child(1) .ant-progress-text {
    color: #fe3737 !important;
  }

  .ant-progress:nth-child(2) .ant-progress-text {
    color: #ff9900 !important;
  }

  .ant-progress:nth-child(3) .ant-progress-text {
    color: #f8d800 !important;
  }

  .ant-progress:nth-child(4) .ant-progress-text {
    color: #c48cf2 !important;
  }
`;
