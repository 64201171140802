import React, { useState } from 'react';

import Button from '../../../../components/Button';

import userPhoto from '../../../../assets/comments/userPhoto.png';

import {
  Container,
  Comment,
  Interact,
  Divider,
  ReplyComment,
  Row,
} from './styles';

const Card = () => {
  const [showReplyComment, setShowReplayComment] = useState(false);

  function renderReplayComment() {
    if (showReplyComment) {
      return (
        <ReplyComment>
          <textarea></textarea>
          <Button>Enviar</Button>
        </ReplyComment>
      );
    }
  }

  return (
    <Container>
      <div>Aula: Introdução ao yoga</div>
      <Row>
        <Comment>
          <img src={userPhoto} alt="Foto de Perfil" />
          <div>
            <div>Thales Henrique</div>
            <div>
              Aula incrível! Para quem está aprendendo é ótimo!!! a Pri é uma
              excelente professora. Só tenho a agradecer ao Yoga Co.
            </div>
            <Interact>
              <div>há 5 min</div>
              <Divider />
              <div>Curtir</div>
              <Divider />
              <div onClick={() => setShowReplayComment(!showReplyComment)}>
                Responder
              </div>
            </Interact>
          </div>
        </Comment>
        {renderReplayComment()}
      </Row>
    </Container>
  );
};

export default Card;
