import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';

import { Container, Informations, Row } from './styles';

const Form = () => {
  return (
    <Container>
      <Informations>
        <Row>
          <div>
            <label>Insira o nome do Plano:</label>
            <Input />
          </div>
          <div>
            <label>Insira o valor</label>
            <Input />
          </div>
        </Row>
        <div>
          <label>Anual ou mensal:</label>
          <div>
            <input type="radio" id="yearly" name="plans" checked></input>
            <span for="yearly">Anual</span>
          </div>
          <div>
            <input type="radio" id="monthly" name="plans"></input>
            <span for="monthly">Mensal</span>
          </div>
        </div>
      </Informations>
      <Button>+ Adicionar Plano</Button>
    </Container>
  );
};

export default Form;
