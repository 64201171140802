import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1336px;
  width: 100%;
  padding: 40px 20px;
  margin: 0 auto;
  animation: rendering 0.5s;

  > h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }

  @media (max-width: 66.25rem) {
    text-align: center;
  }
`;

export const Form = styled.form`
  > label {
    display: block;
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #a2a2a2;
    margin: 40px 0px 20px 0px;
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    width: 260px;
    height: 60px;
    margin-top: 40px;
  }

  > div:nth-of-type(3) {
    width: 200px;
  }

  @media (max-width: 66.25rem) {
    > div:nth-of-type(3) {
      margin: 0 auto;
    }

    > button {
      margin: 40px auto;
    }
  }

  @media (max-width: 35rem) {
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 66.25rem) {
    align-items: center;
    flex-direction: column-reverse;
  }
`;

export const Options = styled.div`
  margin-top: 40px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Aller-Light';
    font-size: 1.2rem;
    color: #5c5c5c;
    width: 500px;
    height: 120px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    padding: 20px;
  }

  @media (max-width: 35rem) {
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;
