import styled from 'styled-components';
import { Button as ButtonGeneric } from '../../components/Button';

export const Container = styled.div`
  h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  animation: rendering 0.5s;
`;

export const Row = styled.div`
  display: flex;

  > div:nth-of-type(1) {
    display: flex;

    > div {
      width: 300px;
      height: 50px;
    }

    > img {
      height: 50px;
      margin-left: 40px;
    }
  }

  > a button {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    max-width: 380px;
    height: 60px;
    margin-left: 60px;
  }

  @media (max-width: 53.75rem) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > a button {
      margin-top: 40px;
      margin-left: 0px;
      width: 100%;
    }

    > div:nth-of-type(1) {
      width: 100%;
      > div {
        width: 100%;
      }
    }
  }
`;
