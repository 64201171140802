import styled from 'styled-components';

export const Table = styled.table`
  flex: 1;
  font-family: 'Aller-Bold';
  vertical-align: middle;
  margin-left: 2px;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 300px !important;
  th,
  td {
    padding: 30px 30px;
  }
  th {
    font-size: clamp(14px, 3vw, 1rem);
    color: #165011;
  }
  td {
    font-size: clamp(14px, 3vw, 1rem);
    color: #333b52;
  }
  td:last-child {
    font-family: 'Aller-Regular';
    color: #707070;
    font-size: clamp(13px, 3vw, 0.9rem);
  }
  tr:nth-child(odd) {
    background: #f7f7f7;
  }
  @media (max-width: 768px) {
    tbody,
    tr,
    td {
      display: flex;
      flex-direction: column;
      text-align: right;
    }
    thead {
      display: none;
    }
    td {
      position: relative;
    }
    td:before {
      content: attr(data-label);
      position: absolute;
      color: #333b52;
      padding-right: 5px;
      text-align: left;
      font-weight: 600;
      left: 15px;
      width: 50%;
    }
  }
`;

export const DynamicTD = styled.td`
  color: ${(props) =>
    props.warning === 'danger'
      ? '#FE3737'
      : props.warning === 'alert'
      ? '#FF9900'
      : '#F8D800'}!important;
`;
