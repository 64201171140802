import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import api from '../../../../../../services/api';

import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import Filters from '../Filters';
import CellPhoneThumbnail from '../CellPhoneThumbnail';
import Modal from '../../../../../../components/Modal';
import Calendar from '../../../../../../components/Calendar';
import LoadModal from '../../../../../../components/LoadModal';
import Checkbox from '../../../../../../components/Checkbox';

import thumbnailIcon from '../../../../../../assets/registration/thumbnail.png';

import {
  Container,
  Options,
  UploadClass,
  UploadThumbnail,
  Row,
  CustomAnchor,
  DeleteButton,
  Tooltip,
  ContainerCheckbox,
} from './styles';

const Podcasts = ({ podcastId }) => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const params = useParams();

  const [editingMode, setEditingMode] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [classUrl, setClassUrl] = useState('');
  const [newThumbnailAdded, setNewThumbnailAdded] = useState(false);
  const [thumbPreview, setThumbPreview] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [editingSeriesId, setEditingSeriesId] = useState('');
  const [is_separated, setIsSeparated] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteButtonText, setDeleteButtonText] = useState('Tem certeza?');

  const [calendarIsVisible, setCalendarIsVisible] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [classIsFree, setClassIsFree] = useState(false);
  const [classPartOfChannel, setClassPartOfChannel] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [modalText, setModalText] = useState('');

  let uploadButton = useRef();

  useEffect(() => {
    if (params.id) {
      api.get(`episodes/${params.id}`).then(
        (response) => {
          console.log(response.data);
          fillData(response.data);
          setEditingMode(true);
        },
        (error) => {
          console.log(error.data);
        },
      );
    }
  }, []);

  function fillData(response) {
    setName(response.name);
    setDescription(response.description);
    setClassUrl(response.url);
    setThumbPreview(response.thumbnail_url);
    setEditingSeriesId(response.series_id);
    // setClassIsFree(response.is_free);
    setClassPartOfChannel(response.is_from_the_channel);

    const filters = response.filters.map((filter) => {
      return { filter: filter.name, id: filter.id };
    });

    setSelectedFilters(filters);
  }

  // function renderCalendar() {
  //   if (calendarIsVisible) {
  //     return <Calendar setVisible={setCalendarIsVisible} />;
  //   }
  // }

  function renderModal() {
    if (modalIsVisible) {
      return (
        <Modal
          name="Sucesso!"
          description={modalText}
          type="success"
          setVisible={setModalIsVisible}
        />
      );
    }
  }

  function handleUploadThumbnail() {
    uploadButton.click();
  }

  function handleSelectThumb(event) {
    if (!event.target.files) {
      return;
    }
    setNewThumbnailAdded(true);

    const selectedImage = Array.from(event.target.files);
    setThumbnail(selectedImage);

    const selectedImagePreview = selectedImage.map((image) => {
      return URL.createObjectURL(image);
    });

    setThumbPreview(selectedImagePreview);
  }

  async function handleSubmitChanges() {
    if (selectedFilters.length < 1) {
      setErrorMessage('Os filtros são Obrigatórios');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    let filterIds = [];
    for (let i = 0; i < selectedFilters.length; i++) {
      const element = selectedFilters[i].id;
      filterIds.push(element);
    }

    const newData = {
      name: name,
      description: description,
      url: classUrl,
      filter_ids: filterIds,
      is_free: classIsFree,
      is_from_the_channel: classPartOfChannel,
    };

    setSubmitAttempt(true);

    setShowLoadModal(true);

    if (newThumbnailAdded) {
      uploadNewThumbNail();
    } else {
      submitChanges(newData);
    }

    async function uploadNewThumbNail() {
      const dataImage = new FormData();

      thumbnail.forEach((image) => {
        dataImage.append('thumbnail', image);
      });

      try {
        const addThumb = await api.patch(
          `episodes/${params.id}/thumbnail`,
          dataImage,
        );

        if (addThumb) {
          submitChanges(newData);
        }
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setShowLoadModal(false);
      }
    }

    async function submitChanges(newData) {
      try {
        const response = await api.put(`episodes/${params.id}`, newData);

        if (response) {
          console.log(response.data | response.data.message);
          if (pathname.includes('meditation')) {
            setModalText('Meditação atualizada');
          } else {
            setModalText('Podcast atualizado');
          }

          setModalIsVisible(true);

          setTimeout(() => {
            if (pathname.includes('meditation')) {
              history.push('/conteudo/lista-de-meditacoes');
            } else {
              history.push('/conteudo/lista-de-podcasts');
            }
          }, 3000);
        }
      } catch (error) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        setSubmitAttempt(false);
      }

      setShowLoadModal(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (selectedFilters.length < 1) {
      setErrorMessage('Os filtros são Obrigatórios');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    if (editingMode) {
      return handleSubmitChanges();
    }

    let filterIds = [];
    for (let i = 0; i < selectedFilters.length; i++) {
      const element = selectedFilters[i].id;
      filterIds.push(element);
    }

    setSubmitAttempt(true);

    const data = new FormData();
    data.append('name', name);
    data.append('description', description);
    data.append('is_separated', is_separated);

    if (podcastId) {
      data.append('podcast_id', podcastId);
    }
    // if (!podcastId) {
    //   setIsSeparated(true);
    //   data.append('podcast_id', );
    // }

    data.append('url', classUrl);
    data.append('is_free', classIsFree);
    data.append('is_from_the_channel', classPartOfChannel);

    thumbnail.forEach((image) => {
      data.append('thumbnail', image);
    });

    filterIds.forEach((filter) => {
      data.append('filter_ids[]', filter);
    });

    if (pathname.includes('meditations')) {
      data.append('type', 'meditacao');
    } else {
      data.append('type', 'podcast');
    }

    setShowLoadModal(true);
    setErrorMessage('');

    try {
      const response = await api.post('episodes', data);

      if (response) {
        console.log(response.data | response.data.message);
        if (pathname.includes('meditation')) {
          setModalText('Meditação criada');
        } else {
          setModalText('Podcast criado');
        }
        setModalIsVisible(true);

        setTimeout(() => {
          if (pathname.includes('meditation')) {
            history.push('/conteudo/lista-de-meditacoes');
          } else {
            history.push('/conteudo/lista-de-podcasts');
          }
        }, 3000);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setSubmitAttempt(false);
    }

    setShowLoadModal(false);
  }

  function renderSubmitButton() {
    if (editingMode) {
      return <Button disabled={submitAttempt}>Salvar</Button>;
    }

    return <Button disabled={submitAttempt}>Adicionar aula</Button>;
  }

  function handleDelete() {
    setSubmitAttempt(true);
    setShowLoadModal(true);
    api.delete(`episodes/${params.id}`).then(
      (response) => {
        if (response) {
          console.log(response.data | response.data.message);
          if (pathname.includes('meditation')) {
            setModalText('Meditação deletada');
          } else {
            setModalText('Podcast deletado');
          }
          setShowLoadModal(false);
          setModalIsVisible(true);

          setTimeout(() => {
            if (pathname.includes('meditation')) {
              history.push('/conteudo/lista-de-meditacoes');
            } else {
              history.push('/conteudo/lista-de-podcasts');
            }
          }, 5000);
        }
      },
      (error) => {
        setShowLoadModal(false);
        setErrorMessage(error.response.data.message);
        setSubmitAttempt(false);
      },
    );
  }

  function renderScheduleLesson() {
    if (!editingMode) {
      return (
        //   <CustomAnchor onClick={() => setCalendarIsVisible(true)}>
        //   Agendar aula
        // </CustomAnchor>
        <span></span>
      );
    }
  }

  function renderDeleteButton() {
    if (editingMode) {
      if (confirmDelete) {
        return (
          <DeleteButton onClick={() => handleDelete()} disabled={submitAttempt}>
            {deleteButtonText}
          </DeleteButton>
        );
      }
      return (
        <DeleteButton onClick={() => setConfirmDelete(true)}>
          Deletar aula
        </DeleteButton>
      );
    }
  }

  function rendererrorMessage() {
    if (errorMessage !== '') {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Row>
          <div>
            <label>Insira o título</label>
            <Input
              onInput={(event) => setName(event.target.value)}
              value={name}
              required={!editingMode}
            />
            <label>Insira uma descrição</label>
            <textarea
              onInput={(event) => setDescription(event.target.value)}
              value={description}
              required={!editingMode}
            />
            <Options>
              <UploadClass>
                <label>Link do Podcast</label>
                {/* <Button iconLeft={uploadFile}>Subir vídeo ou Áudio</Button> */}
                <Input
                  onInput={(event) => setClassUrl(event.target.value)}
                  value={classUrl}
                  required={!editingMode}
                />
                <span></span>
                {/* <ContainerCheckbox>
                  <Checkbox checked={classIsFree} setChecked={setClassIsFree} />
                  <span>Esse podcast é público?</span>
                </ContainerCheckbox> */}
                {/* <ContainerCheckbox>
                  <Checkbox
                    checked={classPartOfChannel}
                    setChecked={setClassPartOfChannel}
                  />
                  <span>Esse podcast faz parte do canal da Pri ?</span>
                </ContainerCheckbox> */}
                {rendererrorMessage()}
                {renderSubmitButton()}
              </UploadClass>
              <UploadThumbnail>
                <label>Adicionar Thumbnail</label>
                <CustomAnchor onClick={() => handleUploadThumbnail()}>
                  <img src={thumbnailIcon} />
                  Anexar Arquivo
                </CustomAnchor>
                <input
                  ref={(input) => (uploadButton = input)}
                  onChange={handleSelectThumb}
                  type="file"
                  required={!thumbPreview}
                />

                {renderScheduleLesson()}
                {renderDeleteButton()}
              </UploadThumbnail>
            </Options>
          </div>
          <section>
            <label>Definir Filtros:</label>
            <Filters
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <CellPhoneThumbnail
              name={name}
              description={description}
              thumbPreview={thumbPreview}
            />
          </section>
        </Row>
        {/* {renderCalendar()} */}
        {renderModal()}
      </form>
      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default Podcasts;
