import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Header from '../../../../components/Header';
import Podcasts from '../PodcastRegistration/components/Podcasts';

import { Container, Content } from './styles';

export const AddClass = () => {
  const location = useLocation();
  const { pathname } = location;
  const params = useParams();
  const [podcastId, setPodcastId] = useState();

  const renderTitle = () => {
    if (pathname.includes('meditation')) {
      return <h1>Adicionar Meditação</h1>;
    }
    return <h1>Adicionar Podcast</h1>;
  };

  useEffect(() => {
    setPodcastId(params.id);
  }, []);
  return (
    <Container>
      <Header />
      <Content>
        {renderTitle()}
        <Podcasts podcastId={podcastId} />
      </Content>
    </Container>
  );
};

export default AddClass;
