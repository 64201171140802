import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 480px;
  background-color: #61e171;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 0px;
  outline: 0;
  padding: 0px 10px;
  font-family: 'Aller-Bold';
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
  /* transition: all 0.3s; */
  &:hover {
    opacity: 0.9 !important;
  }
`;

export const IconLeft = styled.img`
  margin-right: 10px;
`;
