import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: 'Aller-Regular';
  font-weight: 300;
  min-width: 100%;
  color: #165011;
  background-color: #fff;
  padding: 15px;
  border-top: 1px solid #efebeb;
`;

export const Box = styled.div`
  border: 1px solid #bdbaba;
  padding: 3px 5px;
  border-radius: 18px;
  margin: 5px;
  cursor: pointer;
  animation: rendering 0.3s;
`;
