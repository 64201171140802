import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import api from '../../../../services/api';

import Button from '../../../../components/Button';

import serieThumbnail from '../../../../assets/editSeries/example-serie-img.png';
import watch from '../../../../assets/editSeries/watch.png';
import editIcon from '../../../../assets/frequentlyAskedQuestions/pencil-icon.png';
import checkedCircle from '../../../../assets/checked-circle.png';

import {
  Container,
  Row,
  Thumbnail,
  Name,
  EditIcon,
  Time,
  Description,
  Informations,
  Divider,
  Feedback,
} from './styles';

const Card = ({ podcast }) => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [is_published, setIsPublished] = useState(podcast.is_published);

  function handlePublish(id) {
    setDisabled(true);

    const published = {
      is_published: true,
    };

    api.patch(`episodes/${id}/published`, published).then(
      (response) => {
        console.log(response.data);
        setDisabled(false);
        setIsPublished(true);
      },
      (error) => {
        console.log(error);
        alert(error);
        setDisabled(false);
      },
    );
  }

  function handleUnpublish(id) {
    setDisabled(true);

    const published = {
      is_published: false,
    };

    api.patch(`episodes/${id}/published`, published).then(
      (response) => {
        console.log(response.data);
        setDisabled(false);
        setIsPublished(false);
      },
      (error) => {
        console.log(error);
        alert(error);
        setDisabled(false);
      },
    );
  }

  function renderButton() {
    if (is_published) {
      return (
        <Feedback
          onClick={() => handleUnpublish(podcast.id)}
          disabled={disabled}
        >
          Publicado <img src={checkedCircle} />
        </Feedback>
      );
    }
    return (
      <Button onClick={() => handlePublish(podcast.id)} disabled={disabled}>
        Publicar
      </Button>
    );
  }

  const renderEditButton = () => {
    if (pathname.includes('meditacao')) {
      return (
        <Link to={`/edit-meditation/${podcast.id}`}>
          <EditIcon src={editIcon} alt="Ícone de editar" />
        </Link>
      );
    } else {
      return (
        <Link to={`/edit-podcast/${podcast.id}`}>
          <EditIcon src={editIcon} alt="Ícone de editar" />
        </Link>
      );
    }
  };

  return (
    <Container>
      <Row>
        <Thumbnail src={podcast.thumbnail_url} alt="Thumbnail da aula" />
        <Informations>
          <Row>
            <div>
              <Name>{podcast.name}</Name>
              <Time>
                <img src={watch} alt="Ícone de relógio" />
                {podcast.duration}
              </Time>
            </div>
            {renderEditButton()}
          </Row>
          <Divider></Divider>
          <Row>
            <Description
              onClick={() => history.push(`/edit-podcast/${podcast.id}`)}
            >
              {podcast.description}
            </Description>
            {renderButton()}
          </Row>
        </Informations>
      </Row>
    </Container>
  );
};

export default Card;
