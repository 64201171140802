import success from '../../assets/modal/success.png';
import close from '../../assets/modal/close.png';

import { Container } from './styles';

const Modal = ({ title, description, type, setVisible }) => {
  function renderIcon() {
    if (type === 'success') {
      return <img src={success} alt="Ícone de sucesso" />;
    }
  }

  return (
    <Container type={type}>
      <div onClick={() => setVisible(false)}>
        <img src={close} alt="Ícone de fechar o modal" />
      </div>
      {renderIcon()}
      <span>{title}</span>
      <span>{description}</span>
    </Container>
  );
};

export default Modal;
