import React, { useState } from 'react';

import { Calendar as CalendarComponent } from 'react-calendar';
import './calendar.css';

import { Container, CalendarContainer, Divider, Button } from './styles';

const Calendar = ({ setVisible }) => {
  const [value, onChange] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);

  function showLog() {
    const button = document.querySelectorAll('button');
    console.log(button);
  }

  function handleDateSelect() {
    setShowCalendar(false);
    console.log(value);
  }

  function renderCalendar() {
    if (showCalendar) {
      return (
        <CalendarContainer>
          <CalendarComponent
            onChange={onChange}
            onClick={() => showLog()}
            value={value}
            selectRange={true}
          />
          <Divider></Divider>
          <span onClick={() => handleDateSelect()}>OK!</span>
        </CalendarContainer>
      );
    }
  }

  return (
    <Container>
      <Button onClick={() => setShowCalendar(true)}>Período</Button>
      {renderCalendar()}
    </Container>
  );
};

export default Calendar;
