import React, { useState, useEffect } from 'react';

import api from '../../../../../../services/api';

import FilterBox from './components/FilterBox';
import ChoosenFilters from './components/ChoosenFilters';

import { Container, Item } from './styles';

import arrowDownFilter from '../../../../../../assets/registration/arrow-down-filter.png';

const Filters = ({ selectedFilters, setSelectedFilters }) => {
  const [filters, setFilters] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);

  const [currentFilter, setCurrentFilter] = useState('');

  useEffect(() => {
    api.get('filter-categories?embed%5B%5D=filters').then((response) => {
      setFilters(response.data);
    });
  }, []);

  function handleOnClick(filterSelected, filtersOptions) {
    setCurrentFilter(filterSelected)
    console.log(currentFilter);
    setFilterOptions(filtersOptions);
      setShowFilter(true);

  }

  if (!filters) {
    return (
      <Container>
        <p>Carregando....</p>;
      </Container>
    );
  }

  return (
    <Container>
      {filters.map((filter) => {
        return (
          <Item
            onClick={() => handleOnClick(filter.name, filter.filters)}
            key={filter.name}
          >
            {filter.name}
            <img src={arrowDownFilter} alt="Ícone de flecha para baixo" />
          </Item>
        );
      })}

      <FilterBox
        showFilter={showFilter}
        filterOptions={filterOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <ChoosenFilters
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </Container>
  );
};

export default Filters;
