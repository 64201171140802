import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Card from './components/Card';

import editIcon from '../../assets/frequentlyAskedQuestions/pencil-icon.png';

import { Container, Row, Content } from './styles';

const Classes = () => {
  const [lessons, setLessons] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    api.get('lessons').then((response) => {
      setLessons(response.data);
      setSearchResults(response.data);

    });
  }, []);

  function renderLessons() {
    if (lessons) {
      return searchResults.map((lesson) => {
        return <Card lesson={lesson} key={lesson.id} />;
      });
    }
  }

  useEffect(() => {
    const results = lessons.filter((lesson) =>
      lesson.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    setSearchResults(results);
  }, [searchTerm]);



  return (
    <Container>
      <Header />
      <Content>
        <h1>Aulas</h1>
        <Row>
          <div>
            <Input               
            type="text"
              onChange={(event) => setSearchTerm(event.target.value)}
              value={searchTerm}/>
            <img src={editIcon} alt="Ícone de editar nome da aula" />
          </div>
          <Link to="/conteudo">
            <Button>Adicionar nova aula</Button>
          </Link>
        </Row>
        <div>
          {/* {lessons.map((lesson) => {
            return <Card lesson={lesson} key={lesson.id} />;
          })} */}
          {renderLessons()}
        </div>
      </Content>
    </Container>
  );
};

export default Classes;
