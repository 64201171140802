import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';

import Card from './components/Card';

import { Container, Content } from './styles';

const ListSeries = () => {
  const location = useLocation();
  const { pathname } = location;
  const [series, setSeries] = useState([]);
  const [detachedEpisodes, setDetachedEpisodes] = useState('');
  const [playlistAvulsaID, setPlaylistAvulsaID] = useState('');
  const [
    detachedExclusiveMeditationID,
    setDetachedExclusiveMeditationID,
  ] = useState('');
  const [
    detachedExclusiveMeditation,
    setDetachedExclusiveMeditation,
  ] = useState('');

  //mudar id em produção

  useEffect(() => {
    let type;
    let playlistAvulsaIDLocal;
    let exclusiveMeditationIDlocal;

    if (pathname.includes('lista-de-meditacoes')) {
      type = 'meditacao';
      setPlaylistAvulsaID(process.env.REACT_APP_SINGLE_MEDITATIONS);
      playlistAvulsaIDLocal = process.env.REACT_APP_SINGLE_MEDITATIONS;

      setDetachedExclusiveMeditationID(
        process.env.REACT_APP_SINGLE_EXCLUSIVE_MEDITATIONS,
      );
      exclusiveMeditationIDlocal =
        process.env.REACT_APP_SINGLE_EXCLUSIVE_MEDITATIONS;

      api
        .get(`podcast-series/${exclusiveMeditationIDlocal}`)
        .then((response) => {
          setDetachedExclusiveMeditation(response.data);
        });
    } else {
      type = 'podcast';
      setPlaylistAvulsaID(process.env.REACT_APP_EXCLUSIVE_PODCAST_EXCLUSIVE);
      playlistAvulsaIDLocal = process.env.REACT_APP_EXCLUSIVE_PODCAST_EXCLUSIVE;
    }

    api.get(`/podcast-series?type=${type}`).then((response) => {
      setSeries(response.data);
    });

    api.get(`podcast-series/${playlistAvulsaIDLocal}`).then((response) => {
      setDetachedEpisodes(response.data);
    });
  }, []);

  function renderDetachedPlaylist() {
    if (detachedEpisodes) {
      return (
        <Card
          serie={detachedEpisodes}
          key={detachedEpisodes.id}
          showEditButton={false}
        />
      );
    }
  }

  function renderExclusiveMeditationSerie() {
    if (detachedExclusiveMeditation) {
      return (
        <Card
          serie={detachedExclusiveMeditation}
          key={detachedExclusiveMeditation.id}
          showEditButton={false}
        />
      );
    }
  }

  function renderTitle() {
    if (pathname.includes('meditacoes')) {
      return <h1>Meditações</h1>;
    } else {
      return <h1>Podcast</h1>;
    }
  }

  return (
    <Container>
      <Header />
      <Content>
        {renderTitle()}
        <div>
          {renderDetachedPlaylist()}
          {renderExclusiveMeditationSerie()}
          {series
            .filter(
              (serie) =>
                serie.id !== playlistAvulsaID &&
                serie.id !== detachedExclusiveMeditationID,
            )
            .map((serie) => {
              return <Card serie={serie} key={serie.id} />;
            })}
        </div>
      </Content>
    </Container>
  );
};

export default ListSeries;
