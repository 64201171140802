import styled from 'styled-components';
import Button from '../../components/Button';

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1336px;
  width: 100%;
  padding: 40px 20px;
  margin: 0 auto;
  animation: rendering 0.5s;

  > h1 {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: #165011;
    margin-bottom: 40px;
  }
`;

export const TextArea = styled.textarea`
  font-family: 'Aller-Regular';
  font-size: 1.5rem;
  color: #a2a2a2;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 20px 44px 20px 20px;
  outline: 0;
  border: 0;
  width: 480px;
  height: 400px;

  @media (max-width: 35rem) {
    width: 100%;
  }
`;

export const TextAreaContainer = styled.div`
  position: relative;
  width: 480px;
  > img {
    position: absolute;
    bottom: 20px;
    right: 10px;
  }

  @media (max-width: 35rem) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;

  > div {
    > button {
      font-family: 'Aller-Regular';
      font-size: 1.5rem;
      width: 260px;
      height: 60px;
      margin-left: 40px;
    }
    > button:nth-of-type(1) {
      display: ${(props) => (props.showSaveButton ? 'none' : 'block')};
    }

    > button:nth-of-type(2) {
      display: ${(props) => (props.showSaveButton ? 'block' : 'none')};
    }
  }

  align-items: ${(props) => (props.showSaveButton ? 'flex-end' : 'flex-start')};

  @media (max-width: 51.25rem) {
    flex-direction: column;
    align-items: center;

    > div {
      > button {
        margin-top: 40px;
        margin-left: 0px;
      }
    }
  }
`;
