import Header from '../../../../components/Header';
import Series from '../FormRegistration/components/Series';

import { Container, Content } from './styles';

export const EditSeries = () => {
  return (
    <Container>
      <Header />
      <Content>
        <h1>Editar Série</h1>
        <Series />
      </Content>
    </Container>
  );
};

export default EditSeries;
