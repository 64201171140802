import React, { useEffect } from 'react';

import api from '../../../../services/api';

import { Container, Series, Card, Thumbnail, Classes } from './styles';

import serieThumbnail from '../../../../assets/editSeries/example-serie-img.png';
import rolo from '../../../../assets/reports/rolo.svg';
import watch from '../../../../assets/editSeries/watch.png';

const ClassInfo = () => {
  useEffect(() => {
    api.get('lessons?aggregation=ranking').then((response) => {
      console.log(response.data);
    });
  });

  return (
    <Container>
      <Series>
        <h3>Série mais assistida</h3>
        <Card>
          <Thumbnail src={serieThumbnail} alt="Thumbnail da Aula" />
          <div>
            <span>
              <img src={rolo} />
              25 aulas
            </span>
            <span>
              <img src={watch} />
              Duração: 150 horas
            </span>
          </div>
          <div>
            <small>Visualizações</small>
            <h2>1000</h2>
          </div>
        </Card>
      </Series>
      <Classes>
        <h3>Aulas mais assistidas</h3>
        <div>
          <span></span>
          <em>Inicante - Seu guia definitivo </em>
          <b>
            <span>300</span>Visualizações
          </b>
        </div>
        <div>
          <span></span>
          <em>Inicante - Seu guia definitivo</em>
          <b>
            <span>300</span>Visualizações
          </b>
        </div>
        <div>
          <span></span>
          <em>Inicante - Seu guia definitivo </em>
          <b>
            <span>300</span>Visualizações
          </b>
        </div>
        <div>
          <span></span>
          <em>Inicante - Seu guia definitivo </em>
          <b>
            <span>300</span>Visualizações
          </b>
        </div>
        <div>
          <span></span>
          <em>Inicante - Seu guia definitivo </em>
          <b>
            <span>300</span>Visualizações
          </b>
        </div>
      </Classes>
    </Container>
  );
};

export default ClassInfo;
